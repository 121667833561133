import React from 'react';
import { Subscription } from '../../typings';

interface P {
    data: Subscription;
}

interface S {
}

export default class SubscriptionDataRow extends React.Component<P,S> {

    constructor(props: any){
        super(props);

        this.state = {
        }

    }

    render() {
        const { data } = this.props;

        let statusClass = "";
        switch(data.status) {
            case "RENEWABLE":
                statusClass = "text-warning";
                break;
            case "ACTIVE":
                statusClass = "text-success";
                break;
            case "EXPIRED":
                statusClass = "text-danger";
                break;
            default:
                break;
        }
        return (
            <tr>
                <td>
                    { this._renderButton() }
                </td>
                <td>
                    {data.name}
                </td>
                <td>
                    {data.start_date}
                </td>
                <td>
                    {data.end_date}
                </td>
                <td className={statusClass}>
                    {data.status}
                </td>
            </tr>
        )
    }

    _renderButton = () => {
        return null;
        /* if(this.state.isDownloading){
            return (
                <div className="btn btn-sm btn-success download-btn h-100">
                    <i className="fa fa-spin fa-spinner"></i>
                </div>
            )
        } */

        /* return (
            <button className="btn btn-sm btn-success download-btn h-100" onClick={ () => this._onDownload() }>
                <i className="fa fa-download"></i>
            </button>
        ) */
    }

    /* _onDownload = async () => {
        if(this.state.isDownloading){
            return;
        }

        const { data } = this.props;
        let url = `/invoice/${data.id}/download`;
        
        try {
            this.setState({
                isDownloading: true,
            });

            let res = await axios.get<any, AxiosResponse<Blob>>(url, { responseType: "blob" });

            //actual download of the blob
            var url2 = window.URL.createObjectURL(res.data);
            var a = document.createElement('a');
            a.href = url2;
            a.download = data.name + ".pdf";
            a.click();
            a.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);
            
            this.setState({
                isDownloading: false,
            });
        }

        catch {
            this.setState({
                isDownloading: false,
            });
            alert('Error downloading! Please try again.');
        }
    } */
}