import React, { Component } from 'react'
import './style.scss'

import Header from '../../Components/Header';
import { getUser } from '../../utils/utils';

import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY, COMPANY_NAME, COMPANY_REG_ID, COMPANY_ADDRESS, COMPANY_TELEPHONE, COMPANY_EMAIL } from '../../Constants/stringConstants';
import { COMPANY_LATITUDE, COMPANY_LONGITUDE } from '../../Constants/numberConstants';

//font-awesome
import 'font-awesome/css/font-awesome.min.css';

import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';
import MapMarker from '../../Components/MapMarker';
import ReCAPTCHAForm from '../../Components/ReCAPTCHAForm';
import axios from '../../Services/axios';
import { AuthState, Contact, DefaultAPIResponse } from '../../typings';
import { AxiosResponse } from 'axios';

const initialContact: Contact = {
    message: "",
    name: "",
    phone: "",
    email: "",
    plan: "",
    location: "",
}

interface P {
    isMobile: boolean;
}

interface S {
    user?: AuthState;
    isSignedIn: boolean;
    center: {
        lat: number;
        lng: number;
    }
    zoom: number;
    contact: Contact;
}

const formRef: React.RefObject<ReCAPTCHAForm> = React.createRef();

export class ContactUsPage extends Component<P,S> {
    _isMounted = true;

    constructor(props: P) {
        super(props)
        let user = getUser()

        this.state = {
            user,
            isSignedIn: !!user,
            center: {
                lat: COMPANY_LATITUDE,
                lng: COMPANY_LONGITUDE
              },
            zoom: 17,
            contact: initialContact,
        }
    }


    componentDidMount = () => {
        //scroll top
        window.scrollTo(0, 0)

        if (!this.state.user) {
            this._getUser()
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {
        const { user, center, zoom, } = this.state

        return (
            <div>
                <Header
                    user={user}
                    onSignedInStateChanged={this._getUser}
                    linkObjects={TOP_HEADER_OBJECTS}
                    isMobile={this.props.isMobile}
                />

                <div className="Contact-us-main-wrapper below-header" id="contact-us">
                    
                    {
                        this.props.isMobile && <div className="map-contact-us rounded text-left p-5">
                                                    <h3>Get In Touch For More Details</h3>
                                                    <p>If you have any questions or concerns, please do let us know via contact info below: </p>
                                                    <div className="contact-address">
                                                        <div className="contact-label">Company Name.</div><div className="contact-details">{COMPANY_NAME} ({COMPANY_REG_ID})</div>
                                                        <div className="contact-label">Address.</div><div className="contact-details">{COMPANY_ADDRESS}</div>
                                                        <div className="contact-label">Phone No.</div><div className="contact-details">{COMPANY_TELEPHONE}</div>
                                                        <div className="contact-label">Email.</div><div className="contact-details"><a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a></div>
                                                    </div>
                                                </div>
                    }
                    <div className="cls-google-map">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                            center={ center }
                            zoom={ zoom }
                        >     
                            <MapMarker
                                lat={COMPANY_LATITUDE}
                                lng={COMPANY_LONGITUDE}
                                text="We're Here"
                            />                   
                        </GoogleMapReact>
                        {
                            !this.props.isMobile && <div className="map-contact-us elevated rounded text-left p-5">
                                                        <h3>Get In Touch For More Details</h3>
                                                        <p>If you have any questions or concerns, please do let us know via contact info below: </p>
                                                        <div className="contact-address">
                                                            <div className="contact-label">Company Name.</div><div className="contact-details">{COMPANY_NAME} ({COMPANY_REG_ID})</div>
                                                            <div className="contact-label">Address.</div><div className="contact-details">{COMPANY_ADDRESS}</div>
                                                            <div className="contact-label">Phone No.</div><div className="contact-details">{COMPANY_TELEPHONE}</div>
                                                            <div className="contact-label">Email.</div><div className="contact-details"><a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a></div>
                                                        </div>
                                                    </div>
                        }
                        
                    </div>

                    <div className="contact-us-form p-3 rounded">
                        <h4 className="mb-3 text-left">Contact Us Today!</h4>
                        <ReCAPTCHAForm
                            ref={formRef}
                            onSubmit={this._onSubmit}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <input value={this.state.contact.name} placeholder="Name" className="form-control" onChange={(e) => this.setState({ contact:{ ...this.state.contact, name: e.target.value } })}></input>
                                    <input value={this.state.contact.phone} placeholder="Phone" className="form-control mt-1" onChange={(e) => this.setState({ contact:{ ...this.state.contact, phone: e.target.value } })}></input>
                                    <input value={this.state.contact.email} placeholder="Email" className="form-control mt-1" onChange={(e) => this.setState({ contact:{ ...this.state.contact, email: e.target.value } })}></input>
                                    <input value={this.state.contact.location} placeholder="Location" className="form-control mt-1" onChange={(e) => this.setState({ contact:{ ...this.state.contact, location: e.target.value } })}></input>
                                    <select className="form-control mt-1" value={this.state.contact.plan} onChange={(e) => this.setState({ contact:{ ...this.state.contact, plan: e.target.value } })}>
                                        <option>Addresses</option>
                                        <option>Virtual Office</option>
                                        <option>Virtual Office+</option>
                                        <option>Dedicated Desk</option>
                                        <option>Private Room</option>
                                        <option>Event Space</option>
                                        <option>Meeting Room</option>
                                        <option>Day Pass</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <textarea 
                                        placeholder="Message" 
                                        className="form-control" 
                                        rows={10} 
                                        onChange={(e) => this.setState({ contact:{ ...this.state.contact, message: e.target.value } })}
                                        value={this.state.contact.message}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </ReCAPTCHAForm>
                    </div>
                </div>
            </div>
        )
    }

    _getUser = () => {
        let user = getUser()

        if(!this._isMounted) {
            return;
        }

        this.setState({
            user,
            isSignedIn: !!user,
        })
    }

    _onSubmit = async (recaptcha: string) => {
        let url = '/message';
        let data = {
            ...this.state.contact,
            recaptcha,
        };

        try {
            let res = await axios.post<any, AxiosResponse<DefaultAPIResponse>>(url, data);
            let { success } = res.data;

            if(success) {
                alert('Submitted');
                this.setState({
                    contact: initialContact,
                });
                formRef.current!.reset();
            }
        }

        catch (e) {
            if(e?.response?.data?.message != null){
                alert(e.response.data.message)
            }

            else {
                alert('Error submitting message, please try again!');
            }
        }
    }
}

export default ContactUsPage
