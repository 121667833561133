import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './MobileFooter.scss'
import PropTypes from 'prop-types'

import VerticalIconWithTitle from '../VerticalIconWithTitle/VerticalIconWithTitle'

export class MobileFooter extends Component {

    render() {
        
        return (
            <div className="Mobile-footer-wrapper">
                { this._renderIcons() }
            </div>
        )
    }

    _renderIcons() {
        const { icons } = this.props;
        const iconSize = 25
        if ( Array.isArray( icons ) ){
            return icons.map( ( icon, index ) => 
                <Link 
                    className="Mobile-footer-link" 
                    to={ icon.to }
                    key={ index }
                    style={icon.style}
                >
                    <div className="Mobile-footer-icon-wrapper">
                        <VerticalIconWithTitle
                            iconName={ icon.iconName }
                            title={ icon.title }
                            titleSize={ '0.725rem' }
                            iconSize={ iconSize }
                        />
                    </div>
                </Link>
            )
        }
    }
}

MobileFooter.propTypes = {
    icons: PropTypes.array.isRequired
}

export default MobileFooter
