import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './style.scss';

//icons
import { 
    IoMdMenu,
    IoIosClose
} from 'react-icons/io';

import logoWhite from '../../Icons/Logo.png';
import logoColor from '../../Icons/Logo.png';
import SocialBar from '../SocialBar/SocialBar';

//components
import SideBarIcon from '../SideBarIcon/SideBarIcon';
import LoginModal from '../LoginModal';
import { setUser, clearUser } from '../../utils/utils';
import LoadingIndicator from '../LoadingIndicator';
import { AuthState } from '../../typings';

export type User = {
    token: string,
    phone_num: string,
    first_name: string,
    last_name: string,
}

export type HeaderLink = {
    link: string,
    text: string,
}

export type ModalMode = "login" | "signup"

interface P{
    isMobile: boolean,
    linkObjects: HeaderLink[],
    user?: AuthState,
    onSignedInStateChanged: () => void,
    targetID?: any,
    headerMode?: "border" | "transparent",
}

interface S{
    drawerOpen: boolean,
    showLogInModal: boolean,
    showUserPanel: boolean,
    user?: AuthState,
    loggingIn: boolean,
    modalMode: ModalMode,
    activeClass: string
}

export class Header extends Component<P,S> {
    
    targetElement: HTMLElement | Element | null = null

    constructor( props: P ){
        super( props )

        this.state = {
            drawerOpen: false,
            showLogInModal: false,
            showUserPanel: false,
            user: this.props.user,
            modalMode: "login",
            loggingIn: false,
            activeClass: ""
        }

    }

    componentDidMount() {
        const { targetID } = this.props
        if ( targetID ){
            this.targetElement = document.querySelector( targetID );
        }

        document.addEventListener('scroll', this.handleScroll, true);
    }

    handleScroll = () => {
        if(window.pageYOffset >= 100) {
            let activeClass = 'sticky';
            this.setState({ activeClass });
        }

        if (window.pageYOffset < 100) {
            let activeClass = '';
            this.setState({ activeClass });
        }
    }

    componentDidUpdate = () => {
        const{ showUserPanel } = this.state;

        if ( showUserPanel ){
            //close popups and hovereds on click anywhere and esc key
            document.addEventListener( 'click', this._handleDocumentClick, false )
            document.addEventListener("keydown", this._handleEscKey, false );
        } else {
            //close popups and hovereds on click anywhere and esc key
            document.removeEventListener( 'click', this._handleDocumentClick, false )
            document.removeEventListener("keydown", this._handleEscKey, false );
        }
    }

    _handleDocumentClick = () => {
        if ( this.state.showUserPanel ){
            this.setState({
                showUserPanel: false
            })
        }
    }

    _handleEscKey = (event: KeyboardEvent) => {
        const ESCAPE_KEY_CODE = 27
        if( event.keyCode === ESCAPE_KEY_CODE ){
            if ( this.state.showUserPanel ){
                this.setState({
                    showUserPanel: false
                })
            }
        }
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        const { activeClass } = this.state;

        return (
            <div 
                className={`Header-container layer-4 ${activeClass}`}
            >
                { this._renderHeader() }
            </div>
        )
    }

    _renderHeader() {
        const { isMobile } = this.props;
        if ( isMobile ){
            return ( this._renderMobileHeader() )
        } else {
            return ( this._renderPCHeader() )
        }
    }

    _renderPCHeader(){
        const { user } = this.props
        return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', padding: '0px 70px'}}>
                <div className="Logo-container">
                    <Link 
                        to={{
                            pathname: "/",
                            state:{ user }
                        }}
                        className="TopBar-text-container-right"
                    >
                        <div className="Logo">
                            <img src={ logoColor } alt="logo" className="Logo-img"/>
                        </div>
                    </Link>
                </div>
                <div className="link-container">
                    { 
                        this.props.linkObjects.map( ( obj, index ) => 
                            <Link 
                                key={ index } 
                                to={{ 
                                    pathname: obj.link,
                                    state:{ user }
                                }} 
                                className="TopBar-text-container-right"
                            >
                                { obj.text }
                            </Link>
                        ) 
                    }
                </div>
                
                { this._renderUserButton() }
                { this._renderLogInModal() }
                { this._renderUserPanel() }

            </div>
        )
    }

    _renderMobileHeader(){
        const { drawerOpen } = this.state;
        const { user } = this.props


        if ( this.targetElement ){
            if ( drawerOpen ){
                disableBodyScroll( this.targetElement )
            } else {
                enableBodyScroll( this.targetElement )
            }
        }

        return (
            <div className="mobile-header-wrapper">
                <div className="mobile-logo-wrapper">
                    <Link 
                        to={{
                            pathname: "/",
                            state:{ user }
                        }}
                    >
                    <img src={ logoWhite } alt="mobile-logo" className="mobile-logo"/>
                    </Link>
                </div>

                <div 
                    className="mobile-menu-icon-wrapper"
                    onClick={ () => {
                            this._toggleMobileHeader();
                        }
                    }
                >
                    <IoMdMenu size={ 30 } color='black'/>
                </div>
                <div 
                    className="mobile-menu-wrapper"
                    style={ { transform: `translateX(-${ drawerOpen? 100 : 0 }%)` } }  
                >
                    <div className="mobile-menu">
                        <div 
                            className="mobile-menu-close-btn"
                            onClick={ () => this._toggleMobileHeader() }
                        >
                            <IoIosClose size={ 30 } color="white"/>
                        </div>

                        <div className="mobile-menu-content">
                            <SideBarIcon 
                                iconName="users"
                                title="FOSO"
                                desc=""
                                link="/about-us"
                                iconSize={ 30 }
                            />

                            <SideBarIcon 
                                iconName="handshake"
                                title="Plans"
                                desc=""
                                link="/plans"
                                iconSize={ 30 }
                            />

                            <SideBarIcon 
                                iconName="phone"
                                title="Contact Us"
                                desc=""
                                link="/contact-us"
                                iconSize={ 30 }
                            />

                            {
                                user &&
                                (
                                    /** only renders if user is logged in */
                                    <>
                                    <SideBarIcon 
                                        iconName="price"
                                        title="My Billing Dashboard"
                                        desc=""
                                        link="/bills"
                                        iconSize={ 30 }
                                    />

                                    <SideBarIcon 
                                        iconName="calendar"
                                        title="My Subscriptions"
                                        desc=""
                                        link="/subscriptions"
                                        iconSize={ 30 }
                                    />
                                    </>
                                )
                            }
                        </div>
                    </div>

                    <SocialBar/>

                </div>
            </div>
        )
    }

    _toggleMobileHeader() {
        const { drawerOpen } = this.state;
        this.setState({
            drawerOpen: !drawerOpen
        })
    }

    _renderLogInModal = () => {
        if ( this.props.isMobile ){
            return
        }

        return ( 
            <LoginModal 
                show={ this.state.showLogInModal } 
                onModalClose={ () => { this.setState({ showLogInModal: false}) }}
                onSignIn={ this._onSignIn }
                onFailedSignIn={ this._onFailedSignIn }
            >
            </LoginModal> 
        )
    }

    _renderLoggingIn = () => {
        const { loggingIn } = this.state
        return <LoadingIndicator show={ loggingIn } type="scale" mode="white"/>
    }

    //renders log in or user profile button
    _renderUserButton = () => {
        const { user } = this.props

        if ( !user ){
            return (
                <div className="link-container fixed-size center" style={{ flex: 'none' }}>
                    <button 
                        className='TopBar-text-container-right'
                        onClick={ () => this.setState({ showLogInModal: true }) }
                    >
                        Log In / Sign Up
                    </button>
                </div>
            )
        }

        return (
            <div className="link-container" style={{ flex: 'none' }}>
                <button 
                    id="profile-btn"
                    onClick={ () => this.setState({ showUserPanel: !this.state.showUserPanel }) }
                >
                    <i className="fa fa-user-circle fa-2x"></i>
                </button>
            </div>
        )
    }

    _renderUserPanel = () => {
        const { showUserPanel } = this.state

        if ( !showUserPanel ){
            return
        }

        return (
            <div 
                className='elevated border rounded p-4'
                style={{
                    position: 'absolute',
                    top: '4rem',
                    right: '4.5rem',
                    borderRadius: 10,
                    background: 'white'
                }}
            >
                <div className="mb-2">
                    <strong>Welcome {this.props.user?.name}!</strong>
                </div>
                <ul
                    className='unstyled'
                    style={{
                        listStyle: 'none'
                    }}
                >
                    {/* <li>
                        <Link 
                            className='no-deco btn hover-opacity user-panel-list-item'
                            to='/my-dashboard'
                        >
                            My Dashboard
                        </Link>
                    </li> */}
                    <li>
                        <Link 
                            className='no-deco btn hover-opacity user-panel-list-item mt-2'
                            to='/bills'
                        >
                            My Billing Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link 
                            className='no-deco btn hover-opacity user-panel-list-item mt-2'
                            to='/subscriptions'
                        >
                            My Subscriptions
                        </Link>
                    </li>
                    <li>
                        <Link 
                            className='no-deco btn hover-opacity user-panel-list-item mt-2'
                            to='/settings'
                        >
                            My Settings
                        </Link>
                    </li>
                    <li>
                        <button 
                            className='hover-opacity user-panel-list-item mt-2 w-100'
                            style={{
                                height: 'unset',
                            }}
                            onClick={ this._onSignOut }
                        >
                            Log Out
                        </button>
                    </li>
                </ul>
            </div>
        )
    }

    _onSignIn = ( user: AuthState ) => {
        setUser( user )
        this.setState({
            showLogInModal: false,
            loggingIn: false,
        })

        if(user){
            if(!user.telNo) {
                //redirect to settings
                window.location.href = "/settings";
            }

            else {
                //normal
                this.props.onSignedInStateChanged();
            }
        }
    }

    _onSignOut = () => {
        clearUser()
        this.setState({
            showUserPanel: false,
            loggingIn: false,
        })
        this.props.onSignedInStateChanged()
    }

    _onFailedSignIn = ( res: any ) => {
        if ( typeof( res ) === 'string' ){
            alert( res )
            return
        }
        alert('Sign in failed!')
    }
}

export default Header
