import React, { Component } from 'react';
import './style.scss'
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//custom components
import { getUser } from '../../utils/utils';
import Header from '../../Components/Header';

//constants
import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';
import { AuthState } from '../../typings';

interface P{
    isMobile: boolean;
}

interface S{
    user?: AuthState;
    isSignedIn: boolean;
}

export class PlansPage extends Component<P,S> {

    _isMounted = true;
    
    constructor(props: P) {
        super(props);
        let user = getUser()

        this.state = {
            user,
            isSignedIn: !!user,
        }
    }

    componentDidMount = () => {
        //scroll top
        window.scrollTo(0, 0)
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {

        return (
            <div>
                <Header 
                    user={ this.state.user }
                    onSignedInStateChanged={ this._getUser }
                    linkObjects={ TOP_HEADER_OBJECTS }
                    isMobile={ this.props.isMobile }
                    targetID={ "Join-us-main-container" }
                />
                <div className="row justify-content-center below-header">
                    <div className="border rounded plan-container">
                        <img src="/assets/images/laptop1.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Address</strong>
                            <div>From <strong>RM65*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Address</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Mail Handling</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Dedicated Phone Line</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Phone Answering</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i><strong>Limited</strong> Co-working space</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>High Speed Internet</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Fresh Brewed Coffee</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>24/7 Access</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Meeting Room Access</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Locker</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Printing Discounts</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Meeting Room Discounts</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Corporate Service Discounts</li>
                        </ul>
                    </div>
                    <div className="border rounded plan-container">
                        <img src="/assets/images/big_table.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Virtual Office</strong>
                            <div>From <strong>RM195*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Address</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Mail Handling</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Dedicated Phone Line</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Phone Answering</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i><strong>Limited</strong> Co-working space</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>High Speed Internet</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Fresh Brewed Coffee</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>24/7 Access</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Meeting Room Access</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Locker</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Printing Discounts</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Meeting Room Discounts</li>
                            <li><i className="fa fa-times mr-2" style={{color: "red"}}></i>Corporate Service Discounts</li>
                        </ul>

                    </div>
                    <div className="border rounded plan-container">
                        <img src="/assets/images/chairs1.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Virtual Office+</strong>
                            <div>From <strong>RM455*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Address</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Mail Handling</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Dedicated Phone Line</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Phone Answering</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i><strong>Unlimited</strong> Co-working space</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>High Speed Internet</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Fresh Brewed Coffee</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>24/7 Access</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Meeting Room Access</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Locker</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Printing Discounts</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Meeting Room Discounts</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Corporate Service Discounts</li>
                        </ul>

                    </div>
                    <div className="border rounded plan-container">
                        <img src="/assets/images/chairs3.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Dedicated Desk</strong>
                            <div>From <strong>RM715*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Address</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Mail Handling</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Dedicated Phone Line</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Phone Answering</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i><strong>Dedicated</strong> Co-working space</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i><strong>Workspace Labelling</strong></li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>High Speed Internet</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Fresh Brewed Coffee</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>24/7 Access</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Meeting Room Access</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Locker</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Printing Discounts</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Meeting Room Discounts</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Corporate Service Discounts</li>
                        </ul>

                    </div>
                    <div className="border rounded plan-container">
                        <img src="/assets/images/private_office.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Private Room</strong>
                            <div>From <strong>RM1500*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Address</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Mail Handling</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Dedicated Phone Line</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Phone Answering</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i><strong>Dedicated</strong> Co-working space</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i><strong>Workspace Labelling</strong></li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>High Speed Internet</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Fresh Brewed Coffee</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>24/7 Access</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Meeting Room Access</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Locker</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Printing Discounts</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Meeting Room Discounts</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Corporate Service Discounts</li>
                        </ul>

                    </div>
                    <div className="border rounded plan-container">
                        <img src="/assets/images/chairs2.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Event Space</strong>
                            <div>From <strong>RM195*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Freshly Brewed Coffee</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Video Conferencing</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>4K Smart TV</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Chromecast</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Wireless Speaker</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>6 - 30 Pax Space</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Catering Service (pre-ordered)</li>
                        </ul>

                    </div>
                    <div className="border rounded plan-container">
                        <img src="/assets/images/chairs4.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Meeting Room</strong>
                            <div>From <strong>RM325*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Freshly Brewed Coffee</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Video Conferencing</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>4K Smart TV</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Chromecast</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Wireless Speaker</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>7 Pax Private Room</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Catering Service (pre-ordered)</li>
                        </ul>

                    </div>
                    <div className="border rounded plan-container">
                        <img src="/assets/images/sofa_chair.png" alt="chairs" className="d-block w-100 plan-img"/>
                        <div className="plan-desc-container">
                            <strong>Day Pass</strong>
                            <div>From <strong>RM39*</strong></div>
                        </div>
                        <ul>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>Freshly Brewed Coffee</li>
                            <li><i className="fa fa-check mr-2" style={{color: "green"}}></i>High Speed Internet</li>
                        </ul>

                    </div>
                </div>
            </div>
        )
    }

    _getUser = () => {
        let user = getUser()

        if(!this._isMounted) {
            return;
        }

        this.setState({
            user,
            isSignedIn: !!user,
        })
    }
}

export default PlansPage;
