import React from 'react';
import { Row, Col} from 'react-bootstrap';

//log in api
import { signInWithEmail, signUpWithEmail } from '../../utils/userAPI';
import { ModalMode } from '../Header';
import { AuthState } from '../../typings';


interface P{
    onSuccess: ( user: AuthState ) => void,
    onFailure: ( res: any ) => void,
    mode: ModalMode,
}

interface S{
    email: string,
    password: string,
    confirmPassword: string,
    firstName: string,
    lastName: string,
}


export default class EmailLoginForm extends React.Component<P,S> {

    constructor(props: P){
        super(props);

        this.state = {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            confirmPassword: '',
        }

    }

    render() {
        const { mode } = this.props
        return ( 
            <>
                <Row xs={1} sm={1} md={1} lg={1} xl={1}>
                    { this._renderSignUpColumns() }
                    <Col className="center">
                        <input 
                            className='form-control modal-input mb-1' 
                            placeholder='Email'
                            type='text' 
                            name='email'
                            onChange={ ( event ) => this.setState({ email: event.target.value })}
                        />
                    </Col>
                    <Col className="center">
                        <input 
                            className='form-control modal-input mb-1' 
                            placeholder='Password'
                            type='password' 
                            name='password'
                            onChange={ ( event ) => this.setState({ password: event.target.value })}
                        />
                    </Col>
                    { this._renderConfirmPasswordInput() }
                    <Col>
                        <div className="position-relative w-100 mt-3">
                            <button 
                                className='btn-info center border rounded w-100 p-0'
                                onClick={ this._onButtonClick }
                            >
                                <span style={{ fontWeight: 'bold', fontSize: '0.875rem' }}>{ mode === 'login'? 'Log In' : 'Sign Up' }</span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    _renderSignUpColumns = () => {
        const { mode } = this.props;

        if(mode !== "signup"){
            return null;
        }

        return (
            <>
                <Col className="center">
                    <input 
                        className='form-control modal-input mb-1' 
                        placeholder='First Name'
                        type='text' 
                        name='first_name'
                        onChange={ ( event ) => this.setState({ firstName: event.target.value })}
                    />
                </Col>
                <Col className="center">
                    <input 
                        className='form-control modal-input mb-1' 
                        placeholder='Last Name'
                        type='text' 
                        name='last_name'
                        onChange={ ( event ) => this.setState({ lastName: event.target.value })}
                    />
                </Col>
            </>
        );
    }

    _renderConfirmPasswordInput = () => {
        const { mode } = this.props;

        if(mode !== "signup"){
            return null;
        }

        return (
            <Col className="center">
                <input 
                    className='form-control modal-input' 
                    placeholder='Confirm Password'
                    type='password' 
                    name='confirm_password'
                    onChange={ ( event ) => this.setState({ confirmPassword: event.target.value })}
                />
            </Col>
        );
    }

    _onButtonClick = async () => {
        const { mode } = this.props
        if ( mode === 'login' ){
            this._onLogInClick()
        }

        if ( mode === 'signup' ){
            this._onSignUpClick()
        }
    }

    _onLogInClick = async () => {
        const { email, password } = this.state

        if ( !email ){
            this.props.onFailure('Please insert email.')
            return
        }

        if ( !password ){
            this.props.onFailure('Please insert password.')
            return
        }

        try {
            let res = await signInWithEmail( email, password );

            if(res !== false){
                this.props.onSuccess( res );
            }

            else {
                throw new Error("Unable to sign in!");
            }
        }

        catch {
            this.props.onFailure(false);
            return;
        }
    }   

    _onSignUpClick = async() => {
        const { email, password, confirmPassword, firstName, lastName } = this.state

        if ( !firstName ){
            alert( 'Please set first name!' )
            return
        }

        if ( !lastName ){
            alert( 'Please set last name!' )
            return
        }

        if ( !email ){
            alert( 'Please set email!' )
            return
        }

        if ( !password ){
            alert( 'Please input password!' )
            return
        }

        if ( !confirmPassword || confirmPassword !== password ){
            alert( 'Passwords don\'t match!' )
            return
        }

        let user = await signUpWithEmail( firstName, lastName, email, password );
        
        if(user !== false){
            this.props.onSuccess( user );
        }

        else {
            this.props.onFailure("Unable to sign in!");
        }

    }

}