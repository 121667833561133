import React, { Component } from 'react';
import './style.scss';
interface P {

}

interface S {

}

class CampaignThankYou extends Component<P,S> {
    render() {
        return (
            <div className="campaign-ty-page">
                <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
                    <i className="fa fa-check-circle tick"></i>
                    <div className="thank-you-text">THANK YOU! YOU'RE AWESOME! <br/> WE WILL BE IN TOUCH SOON!</div>
                    <a href="/" className="btn btn-sm btn-info home-button">Go to Home Page</a>
                </div>
            </div>
        )
    }
}

export default CampaignThankYou;