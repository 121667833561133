import React, { Component } from 'react'

import { getUser } from '../../utils/utils';

//font-awesome
import 'font-awesome/css/font-awesome.min.css';

import axios from '../../Services/axios';
import { AuthState, APIResponse } from '../../typings';
import { AxiosResponse } from 'axios';
import { RouteComponentProps } from 'react-router';

interface P extends RouteComponentProps<any, any> {
    isMobile: boolean;
}

interface S {
    user?: AuthState;
    isSignedIn: boolean;

    isLoading: boolean;
    hasError: boolean;
    image?: string;
}

export class InvoiceImagePage extends Component<P,S> {
    _isMounted = true;

    constructor(props: P) {
        super(props)
        let user = getUser()

        this.state = {
            user,
            isSignedIn: !!user,
            isLoading: true,
            hasError: false,
            image: "",
        }
    }


    componentDidMount = () => {
        //scroll top
        window.scrollTo(0, 0)

        if (!this.state.user) {
            this._getUser()
        }

        this._getImage();
    }

    componentDidUpdate = () => {
        if(!this.state.isSignedIn) {
            window.location.href = "/";
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {
        const { image } = this.state;

        if(!this.state.image){
            return (
                <div className="vh-100 center">
                    <i className="fa fa-2x fa-spin fa-spinner"></i>
                </div>
            );
        }

        return (
            <div className="vh-100">
                <img src={`data:text/html;base64,${image}`} alt="null" className="h-100"></img>
            </div>
        );
    }

    _getUser = () => {
        let user = getUser()

        if(!this._isMounted) {
            return;
        }

        this.setState({
            user,
            isSignedIn: !!user,
        })
    }

    _getImage = async () => {
        let id = this.props.match.params.id;
        let url = `/invoice/${id}/proof`;
        try {
            this.setState({
                isLoading: true,
                hasError: false,
            });

            let res = await axios.get<any, AxiosResponse<APIResponse<string>>>(url);
            let { success, data } = res.data;

            if(!success) {
                throw new Error();
            }

            this.setState({
                image: data,
                isLoading: false,
                hasError: false,
            });
        }

        catch (e) {
            this.setState({
                isLoading: false,
                hasError: true,
            });
        }
    }
}

export default InvoiceImagePage;
