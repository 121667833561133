import React, { Component } from 'react';
import './style.scss';
import LoginForm from '../../Components/LoginForm/LoginForm';
import Header, { ModalMode } from '../../Components/Header';
import { setUser, clearUser, getUser } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';
import { AuthState } from '../../typings';

interface P {

}

interface S {
    user?: AuthState;
    isLoggedIn: boolean;
    modalMode: ModalMode;
    loggingIn: boolean;
}

export default class LoginPage extends Component<P, S> {

    constructor(props: P) {
        super(props);

        let user = getUser();

        this.state = {
            user,
            isLoggedIn: user !== undefined,
            modalMode: "login",
            loggingIn: false,
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        if(this.state.isLoggedIn){
            window.location.href = "/";
        }
    }

    componentDidUpdate = () => {
        const { user } = this.state;
        if(user){
            if(!user.telNo) {
                window.location.href = "/settings";
            }

            else {
                window.location.href = "/";
            }
        }
    }

    render() {
        const { isLoggedIn } = this.state;

        if (isLoggedIn) {
            return (
                <div>
                    { this._renderHeader() }
                    { this._renderUserPanel() }
                </div>
            )
        }
        else {
            return (
                <div>
                    { this._renderHeader() }
                    <div className="login-page-container">
                        <LoginForm
                            onSuccess={ this._onSignIn }
                            onFailure={ this._onFailedSignIn }
                            onSigningIn={ () => this.setState({ loggingIn: true })}
                        >
                        </LoginForm>
                    </div>
                </div>
            )
        }
    }

    _onSignIn = ( user: AuthState ) => {
        setUser( user )

        this.setState({
            user
        });
    }

    _onFailedSignIn = ( res: any ) => {
        if ( typeof( res ) === 'string' ){
            alert( res )
            return
        }
        alert('Sign in failed!')
    }

    _onSignOut = () => {
        clearUser()
        this.setState({
            isLoggedIn: false
        })
    }

    _renderHeader = () => {
        return(
            <Header
                onSignedInStateChanged={ () => {} }
                linkObjects={ TOP_HEADER_OBJECTS }
                isMobile={ true }
            />
        )
    }

    _renderUserPanel = () => {
        return (
            <div
                style={{
                    padding: '10rem 5rem 5rem'
                }}>
                <ul
                    className='unstyled'
                    style={{
                        listStyle: 'none',
                        display: 'table-row'
                    }}
                >
                    {/* <li>
                        <Link 
                            className='no-deco btn hover-opacity user-panel-list-item'
                            to='/my-reservations'
                        >
                            My Reservations
                        </Link>
                    </li> */}
                    <li>
                        <Link 
                            className='no-deco btn hover-opacity user-panel-list-item'
                            to='/my-dashboard'
                        >
                            My Dashboard
                        </Link>
                    </li>
                    <li>
                        <button 
                            className='hover-opacity user-panel-list-item'
                            onClick={ this._onSignOut }
                        >
                            Log Out
                        </button>
                    </li>
                </ul>
            </div>
        )
    }
}