import React from 'react';
import './style.scss';

//icons
import { 
    FaMapMarkerAlt
} from 'react-icons/fa'

interface P{
    markerID?: number,
    lat: number,
    lng: number,
    text: string,
    onMouseEnter?: ( index: number ) => void,
    onMouseLeave?: () => void,
    onClick?: ( index: number ) => void,
}

interface S{
    hoveredState: boolean,
}


export default class MapMarker extends React.Component<P,S> {

    constructor(props: P){
        super(props);

        this.state = {
            hoveredState: false
        }

    }

    render() {
        const { hoveredState } = this.state;

        return (
            <div 
                className="map-marker-wrapper"
            >
                <div
                    className="map-marker layer-1"
                    style={ { transform: `scale( ${ hoveredState? '1.5, 1.5': '1, 1'} )` } }
                    onMouseEnter={ this._onMouseEnter }
                    onMouseLeave={ this._onMouseLeave }
                >
                    <div className={`map-hint rounded elevated`}>
                        <strong style={{fontSize: "2rem"}}>
                            {this.props.text}
                        </strong>
                    </div>
                    <FaMapMarkerAlt
                        size={ 30 }
                        color='red'
                    />
                </div>
            </div>
        )
    }

    _onMouseEnter = () => {
        const { markerID } = this.props

        this.setState({ hoveredState: true })

        if ( this.props.onMouseEnter && markerID ){
            this.props.onMouseEnter( markerID )
        }
    }

    _onMouseLeave = () => {
        this.setState({ hoveredState: false })

        if ( this.props.onMouseLeave ){
            this.props.onMouseLeave()
        }
    }
}