import React from 'react';

/// <reference path="react-facebook-login.d.ts"/>
import FacebookSignIn from 'react-facebook-login/dist/facebook-login-render-props';

//log in api
import { signInWithFacebook } from '../../utils/userAPI';
import { FACEBOOK_APP_ID } from '../../Constants/stringConstants';
import { ModalMode } from '../Header';
import { FaFacebookF } from 'react-icons/fa';
import { AuthState } from '../../typings';


interface P{
    mode: ModalMode,
    onSigningIn: () => void,
    onSuccess: ( user: AuthState ) => void,
    onFailure: ( errorMessage: string ) => void,
}

interface S{}


export default class FacebookSignInButton extends React.Component<P,S> {

    constructor(props: P){
        super(props);

        this.state = {

        }

    }

    render() {
        return (
            <FacebookSignIn
                appId={ FACEBOOK_APP_ID }
                fields={ [ 'first_name', 'last_name', 'email' ] }
                callback={ this._handleFacebookRes }
                render={ this._renderButton }
            />
        )
    }

    _renderButton = ( renderProps: any ) => {
        const { onSigningIn } = this.props
        return (
            <button 
                className='center border rounded w-100'
                style={{
                    background: '#4267B2',
                }}
                onClick={ () => {
                    if ( onSigningIn ){
                        onSigningIn()
                    }

                    renderProps.onClick()
                }} 
                disabled={renderProps.disabled}
            >
                <div
                    style={{ marginRight: 24 }}
                >
                    <FaFacebookF size={ 24 } color='white'/>
                </div>
                <div className="center">
                    <span 
                        style={{ 
                            fontSize: '0.875rem', 
                            fontFamily: 'Roboto, sans-serif', 
                            fontWeight: 'bold', 
                            color: 'white' 
                        }}>
                            Sign { this.props.mode === 'login'? 'in' : 'up' } with Facebook
                        </span>
                </div>
            </button>

        )
    }

    _handleFacebookRes = async ( res: any ) => {
        const { accessToken } = res

        if ( !accessToken ){
            this.props.onFailure( res )
            return
        }

        let user = await signInWithFacebook( accessToken )

        if(user !== false){
            this.props.onSuccess( user );
        }

        else {
            this.props.onFailure("Facebook sign in failed");
        }
    }   

}