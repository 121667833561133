import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './SideBarIcon.scss'
import PropTypes from 'prop-types'

//icons
import { 
    FaTools,
    FaUsers, 
    FaAirbnb,
    FaHome, 
    FaHandshake,
} from 'react-icons/fa'

import {
    GiMoneyStack
} from 'react-icons/gi'

import { 
    IoIosCalendar, 
    IoIosHeart 
} from 'react-icons/io';

import { 
    MdPhone, MdEmail,

} from 'react-icons/md';

export class SideBarIcon extends Component {

    render() {
        const { title, desc, link } = this.props;
        return (
            <Link 
                className="SideBarIcon-with-desc-wrapper no-deco"
                to={ link }
            >
                <div className="SideBarIcon-wrapper">
                    { this._renderIcon() }
                </div>
                <div className="SideBarIcon-desc-wrapper">
                    <span className="SideBarIcon-title">{ title }</span>
                    <span className="SideBarIcon-desc">{ desc }</span>
                </div>
            </Link>
        )
    }

    _renderIcon() {
        const { iconName, iconSize, color } = this.props;
        var adjIconSize = iconSize? iconSize : 100;
        var adjColor = color? color : "white";

        switch ( iconName ) {
            case "tools":
                return <FaTools size={ adjIconSize } color={ adjColor }/>

            case "users":
                return <FaUsers size={ adjIconSize } color={ adjColor }/>

            case "airbnb":
                return <FaAirbnb size={ adjIconSize } color={ adjColor }/>

            case "price":
                return <GiMoneyStack size={ adjIconSize } color={ adjColor }/>

            case "calendar":
                return <IoIosCalendar size={ adjIconSize } color={ adjColor }/>

            case "heart":
                return <IoIosHeart size={ adjIconSize } color={ adjColor }/>

            case "phone":
                return <MdPhone size={ adjIconSize } color={ adjColor }/>

            case "mail":
                return <MdEmail size={ adjIconSize } color={ adjColor }/>

            case "home":
                return <FaHome size={ adjIconSize } color={ adjColor }/>

            case "handshake":
                return <FaHandshake size={ adjIconSize } color={ adjColor }/>
            
            default:
                break;
        }
    }
}

SideBarIcon.propTypes = {
    iconSize: PropTypes.number,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    color: PropTypes.string
}

export default SideBarIcon
