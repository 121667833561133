import React, { Component } from 'react';
import './style.scss';
import axios from '../../Services/axios';
import { AxiosResponse } from 'axios';
import { DefaultAPIResponse } from '../../typings';

interface P {
    isMobile: boolean;
}

interface S {
    c_1: string;
    c_2: string;
    c_3: string;
    c_5: string;
    c_6: string;
    c_7: string;
    c_8: string;
    c_9: string;
    c_11: string;

    ic: string;
    loe: string;
}

class Campaign extends Component<P,S> {

    icInput: React.RefObject<HTMLInputElement>;
    loeInput: React.RefObject<HTMLInputElement>;

    constructor(props: P) {
        super(props)

        this.icInput = React.createRef<HTMLInputElement>();
        this.loeInput = React.createRef<HTMLInputElement>();

        this.state = {
            c_1: "",
            c_2: "",
            c_3: "",
            c_5: "",
            c_6: "",
            c_7: "1",
            c_8: "1",
            c_9: "1",
            c_11: "1",
            ic: "IC",
            loe: "LOE (Optional)",
        }
    }

    componentDidMount = async () => {
        //scroll top
        window.scrollTo(0, 0);

        //register click
        await axios.post('/campaign/1/click');
    }

    _onSubmit = async() => {
        let url = "/campaign/1/store";
        let formData: FormData = new FormData();
        const { 
            c_1,
            c_2,
            c_3,
            c_5,
            c_6,
            c_7,
            c_8,
            c_9,
            c_11,
        } = this.state;

        if(
            c_1 === "" ||
            c_2 === "" ||
            c_3 === "" ||
            c_5 === "" ||
            c_6 === "" ||
            c_7 === "" ||
            c_8 === "" ||
            c_9 === "" ||
            c_11 === ""
        ) {
            alert('Please fill up all the details');
            console.log(this.state);
            return;
        }

        if(!this.icInput.current || !this.icInput.current.files) {
            alert('Please upload IC');
            return;
        }

        if(this.loeInput.current && this.loeInput.current.files) {
            formData.append("c_10", this.loeInput.current?.files[0]);
        }

        formData.append("c_1", c_1);
        formData.append("c_2", c_2);
        formData.append("c_3", c_3);
        formData.append("c_5", c_5);
        formData.append("c_6", c_6);
        formData.append("c_7", c_7);
        formData.append("c_8", c_8);
        formData.append("c_9", c_9);
        formData.append("c_1", c_11);
        formData.append("c_12", this.icInput.current?.files[0]);

        try {
            let res = await axios.post<any, AxiosResponse<DefaultAPIResponse>>(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            if(!res.data.success) {
                throw new Error(res.data.message);
            }

            this.setState({
                c_1: "",
                c_2: "",
                c_3: "",
                c_5: "",
                c_6: "",
                c_7: "1",
                c_8: "1",
                c_9: "1",
                c_11: "1",
            }, () => {
                window.location.href = "/prihatin/thank-you";
            });
        }

        catch (e: any){
            let message = e.response?.data?.message ?? 'Error occured! Please try again!';
            alert(message);
        }
    }

    render() {
        return (
            <div className="campaign-page">
                <img id="background" src="/pexels-zukiman-mohamad-22804.jpg" alt="null"/>
                <div className="min-vh-100">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 min-vh-100 d-flex align-items-center justify-content-center">
                            <div id="landing-text" className="d-flex flex-column p-5 justify-content-center rounded m-3 ">
                                <h1 className="text-left">FOSOBIZ PRIHATIN CAMPAIGN</h1>
                            
                                <div className="d-flex flex-column text-left">
                                    Did you just hire a NEW STAFF?<br/>
                                    Did you LOSE YOUR JOB due to this pandemic?<br/>
                                    We are here to help! <br/>
                                    Claim from GOVN up to 6 months wages for newly hired staffs<br/>
                                    Receive income support up to 6 months for those who lost their jobs.<br/>
                                    In the mean time, get yourself trained with the latest digital marketing skills;<br/>
                                    To enhance your skillset and broaden your next job search<br/>
                                    learn from professionals on these subjects:<br/>
                                    <ul style={{listStyle: "circle"}}>
                                        <li>Facebook and Insta Marketing</li>
                                        <li>Youtube and Video Marketing</li>
                                        <li>Google Marketing and Advertising</li>
                                        <li>Setting up YOUR.com site</li>
                                        <li>SEO</li>
                                        <li>Email marketing and more</li>
                                    </ul>
                                    All cost are covered under the program.<br/>
                                    (Course worth RM4000)<br/>
                                    So if you are<br/>
                                    <ul style={{listStyle: "decimal"}}>
                                        <li>A Malaysian citizen aged 18-60</li>
                                        <li>A Malaysian company registered with SSM/SOCSO</li>
                                        <li>Working in a private sector and being retrenced</li>
                                    </ul>
                                    <h3>REGISTER WITH US NOW!</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 my-5 d-flex align-items-center justify-content-center">
                            <div className="card" style={{width: "80%"}}>
                                <div className="card-header">
                                    <div className="card-title">
                                        Registration Form
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-left">Is your retrenchment within 60 days form now?</div>
                                    <select name="c_7" value={this.state.c_7} onChange={(e) => { this.setState({ c_7: e.target.value })}} className="form-control mb-3">
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <div className="text-left">Are you aged between 18-60?</div>
                                    <select name="c_8" value={this.state.c_8} onChange={(e) => { this.setState({ c_8: e.target.value })}} className="form-control mb-3">
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <div className="text-left">Do you have your LOE (Loss Of Employment) Letter? i.e termination retrenchment letter</div>
                                    <select name="c_9" value={this.state.c_9} onChange={(e) => { this.setState({ c_9: e.target.value })}} className="form-control mb-3">
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <div className="text-left">Have you applied for PERKESO-EIS</div>
                                    <select name="c_11" value={this.state.c_11} onChange={(e) => { this.setState({ c_11: e.target.value })}} className="form-control mb-3">
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <input type="text" value={this.state.c_3} onChange={(e) => { this.setState({ c_3: e.target.value })}} placeholder="Full Name" className="form-control mb-3" name="c_3"/>
                                    <input type="text" value={this.state.c_1} onChange={(e) => { this.setState({ c_1: e.target.value })}} placeholder="Post Code" className="form-control mb-3" name="c_1"/>
                                    <input type="text" value={this.state.c_6} onChange={(e) => { this.setState({ c_6: e.target.value })}} placeholder="Phone Number" className="form-control mb-3" name="c_6"/>
                                    <input type="text" value={this.state.c_5} onChange={(e) => { this.setState({ c_5: e.target.value })}} placeholder="Email" className="form-control mb-3" name="c_5"/>
                                    <input type="text" value={this.state.c_2} onChange={(e) => { this.setState({ c_2: e.target.value })}} placeholder="Company Name" className="form-control mb-3" name="c_2"/>

                                    <div className="d-flex flex-row mt-4 justify-content-between">
                                        <div className="file-text">{this.state.ic}</div>
                                        <button 
                                            onClick={() => {
                                                if(this.icInput.current) {
                                                    this.icInput.current.click();
                                                }
                                            }} 
                                            className="btn btn-sm btn-info h-100 px-1 py-0"
                                            style={{ width: 100 }}
                                        >
                                        Select File
                                        </button>
                                    </div>
                                    <div className="d-flex flex-row mt-4 justify-content-between">
                                        <div className="file-text">{this.state.loe}</div>
                                        <button 
                                            onClick={() => {
                                                if(this.loeInput.current) {
                                                    this.loeInput.current.click();
                                                }
                                            }} 
                                            className="btn btn-sm btn-info h-100 px-1 py-0"
                                            style={{ width: 100 }}
                                        >
                                            Select File
                                        </button>
                                    </div>
                                    <input 
                                        className="d-none" 
                                        type="file" 
                                        ref={this.icInput} 
                                        accept="image/png, image/jpeg, application/pdf"
                                        onChange={(e) => this.setState({ ic: this.icInput.current!.files? this.icInput.current!.files[0].name : "IC" })}
                                    >
                                    </input>
                                    <input 
                                        className="d-none" 
                                        type="file" 
                                        ref={this.loeInput} 
                                        accept="application/pdf"
                                        onChange={(e) => this.setState({ loe: this.loeInput.current!.files? this.loeInput.current!.files[0].name : "LOE (Optional)" })}
                                    >
                                    </input>
                                    <button onClick={this._onSubmit} className="btn btn-sm btn-success" id="submit-button">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Campaign;