import { USER_EXTENSION } from "../Constants/stringConstants"
import { getUser } from "./utils"
import axios from '../Services/axios';
import { AuthState, APIResponse, UserDetails, DefaultAPIResponse } from "../typings";
import { AxiosResponse } from "axios";

/**
 * Sign in with Google using GoogleSignIn's returned idToken
 * @param string token 
 */
export const signInWithGoogle = async ( token: string ) => {

    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/customer/signIn", {
            token, 
            type: '0'
        });
    
        if (response.data.success) {
            const { token, userId } = response.data.data;
            const auth = `${token}`;
    
            axios.defaults.headers.common['Auth-Token'] = auth;
            axios.defaults.headers.common['Auth-Id'] = userId;
        }
        return response.data.data;
    }

    catch {
        return false;
    }
}

/**
 * Sign in with Facebook using FacebookSignIn's returned access token
 * @param string accessToken 
 */
export const signInWithFacebook = async ( token: string ) => {
    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/customer/signIn", {
            token, 
            type: '1'
        });
    
        if (response.data.success) {
            const { token, userId } = response.data.data;
            const auth = `${token}`;
    
            axios.defaults.headers.common['Auth-Token'] = auth;
            axios.defaults.headers.common['Auth-Id'] = userId;
        }
        return response.data.data;
    }

    catch {
        return false;
    }

}

/**
 * Sign in with registered email and password
 * @param string email 
 * @param string password 
 */
export const signInWithEmail = async ( email: string, password: string ) => {
    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/customer/signIn", {
            "email": email,
            "password": password,
            "type": "2"
        });
        return response.data.data;

    }

    catch {
        return false;
    }
}

/**
 * Sign up using email and password
 * @param firstName 
 * @param lastName 
 * @param email 
 * @param password 
 */
export const signUpWithEmail = async ( firstName: string, lastName: string, email: string, password: string ) => {
    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/customer/signUp", {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
        });
    
        if (response.data.success) {
            const { token, userId } = response.data.data;
            const auth = `${token}`;
    
            axios.defaults.headers.common['Auth-Token'] = auth;
            axios.defaults.headers.common['Auth-Id'] = userId;
        }

        return response.data.data;
    }

    catch {
        return false;
    }
}

export const updateUser = async( details: UserDetails ) => {
    let user = getUser();
    let token = user?.token;

    if ( !token ){
        alert( 'Session expired, please log in again!' )
        return
    }

    const url = `/${ USER_EXTENSION }`;
    return await axios.put<any, AxiosResponse<DefaultAPIResponse>>(url, { ...details });
}

/**
 * Returns true if password changed successfully
 * @param newPassword 
 * @param oldPassword 
 */
export const changePassword = async( newPassword: string, oldPassword: string ) => {
    let user = getUser();
    let token = user?.token;

    if ( !token ){
        alert( 'Session expired, please log in again!' )
        return
    }

    const url = `/${ USER_EXTENSION }/changePassword`
    return await axios.put<any, AxiosResponse<DefaultAPIResponse>>(
        url,
        {
            new_password: newPassword,
            old_password: oldPassword,
        }
    )
}

/**
 * Returns name from token. For token validation purposes.
 * @param token 
 */
export const getUserFromId = async( id: number ) => {
    const url = `/${ USER_EXTENSION }/info`;
    return axios.get<any, AxiosResponse<APIResponse<AuthState>>>(url, { data: { id } });
}

//todo
/**
 * Returns current user reservations
 * @param token 
 */
export const getReservations = async() => {
    const url = `/${ USER_EXTENSION }/reservations`;
    return await axios.get(url);
}

export const getMe = async() => {
    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<UserDetails>>>("/customer/me");
    
        if (!response.data.success) {
            return {};
        }
        
        return response.data.data;
    }

    catch {
        return {};
    }
}