import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import './style.scss'

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//custom components
import { getUser } from '../../utils/utils';
import Header from '../../Components/Header';

//constants
import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';
import { COMPANY_LATITUDE, COMPANY_LONGITUDE } from '../../Constants/numberConstants';
import { GOOGLE_API_KEY, COMPANY_EMAIL, COMPANY_TELEPHONE, COMPANY_ADDRESS, COMPANY_NAME, COMPANY_REG_ID } from '../../Constants/stringConstants';
import MapMarker from '../../Components/MapMarker';

export class About extends Component {
    _isMounted = true;

    constructor(props) {
        super(props);
        let user = getUser()

        this.state = {
            user,
            isSignedIn: !!user,
            center: {
                lat: COMPANY_LATITUDE,
                lng: COMPANY_LONGITUDE
              },
            zoom: 17,
        }
    }

    componentDidMount = async() => {
        //scroll top
        let currentLocation = window.location.href;
        const hasAnchor = currentLocation.includes("/#");

        window.scrollTo(0, 0);
        if(hasAnchor){
            this.scrollToContact();
        }

        if ( !this.state.user ){
            this._getUser()
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    scrollToContact = () => {
        let currentLocation = window.location.href;
        const anchorId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
        if(anchorId !== "contact-us"){
            return;
        }
        const wrapper = document.getElementById(anchorId);
        if(wrapper){
            wrapper.scrollIntoView();
        }
    }

    render() {
        const { center, zoom, } = this.state

        return (
            <div>
                <Header 
                    user={ this.state.user }
                    onSignedInStateChanged={ this._getUser }
                    linkObjects={ TOP_HEADER_OBJECTS }
                    isMobile={ this.props.isMobile }
                    targetID={ "Join-us-main-container" }
                />
                <div className="top-wrapper">
                    <div className="spotlight accent1">
                        <div className="inner">
                            <div className="col-lg-8">
                                <div className="cls-image">
                                    <img src="/assets/images/Table1.jpg" alt="table" />
                                </div>
                                <div className="content">
                                    <p>At <strong>FOSO</strong>, we keep a simple, modern, and functional working environment to maximize your productivity. Relaxing music is played alongside with our aromatic freshly brewed coffee, right from quality italian and arabican beans.
                                    </p>
                                    <p>
                                        Address and workspace ideal for you entrepreneurs, start-ups, freelancers, influencers, to corporates who needs a flexible solution. All your office needs including mail-handling, call handling, admin services, printing, CRM, incorporation, and much more are available here with our team of experienced professionals. You can book our place for events, meetings as well.
                                    </p>
                                    <p>
                                        Change your business lifestyle with FOSO, let us endure through this CMCO and COVID-19 hardtimes, and transform into a better us.
                                    </p>
                                </div>  
                            </div>     
                            <div className="col-lg-4">
                                <div className="cls-image-right">
                                    <img src="/assets/images/Outside1.jpg" alt="outside" />
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>
            
                <div className="Contact-us-main-wrapper" id="contact-us">
                    {
                        this.props.isMobile && <div className="map-contact-us rounded text-left p-5">
                                                    <h3>Get In Touch For More Details</h3>
                                                    <p>If you have any questions or concerns, please do let us know via contact info below: </p>
                                                    <div className="contact-address">
                                                        <div className="contact-label">Company Name.</div><div className="contact-details">{COMPANY_NAME} ({COMPANY_REG_ID})</div>
                                                        <div className="contact-label">Address.</div><div className="contact-details">{COMPANY_ADDRESS}</div>
                                                        <div className="contact-label">Phone No.</div><div className="contact-details">{COMPANY_TELEPHONE}</div>
                                                        <div className="contact-label">Email.</div><div className="contact-details"><a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a></div>
                                                    </div>
                                                </div>
                    }
                    <div className="cls-google-map">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                            center={ center }
                            zoom={ zoom }
                        >     
                            <MapMarker
                                lat={COMPANY_LATITUDE}
                                lng={COMPANY_LONGITUDE}
                                text="We're Here"
                            />                   
                        </GoogleMapReact>
                        {
                            !this.props.isMobile && <div className="map-contact-us elevated rounded text-left p-5">
                                                        <h3>Get In Touch For More Details</h3>
                                                        <p>If you have any questions or concerns, please do let us know via contact info below: </p>
                                                        <div className="contact-address">
                                                            <div className="contact-label">Company Name.</div><div className="contact-details">{COMPANY_NAME} ({COMPANY_REG_ID})</div>
                                                            <div className="contact-label">Address.</div><div className="contact-details">{COMPANY_ADDRESS}</div>
                                                            <div className="contact-label">Phone No.</div><div className="contact-details">{COMPANY_TELEPHONE}</div>
                                                            <div className="contact-label">Email.</div><div className="contact-details"><a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a></div>
                                                        </div>
                                                    </div>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }

    _getUser = () => {
        let user = getUser()

        if(!this._isMounted) {
            return;
        }

        this.setState({
            user,
            isSignedIn: !!user,
        })
    }
}

export default About
