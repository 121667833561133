import React, { Component } from 'react'
import './style.scss'

import Header from '../../Components/Header';
import { getUser } from '../../utils/utils';

//font-awesome
import 'font-awesome/css/font-awesome.min.css';

import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';
import axios from '../../Services/axios';
import { AuthState, APIResponse, Subscription } from '../../typings';
import { AxiosResponse } from 'axios';
import SubscriptionDataRow from '../../Components/SubscriptionDataRow';

interface P {
    isMobile: boolean;
}

interface S {
    user?: AuthState;
    isSignedIn: boolean;

    isLoading: boolean;
    hasError: boolean;

    subscriptions: Subscription[];
}

export class SubscriptionPage extends Component<P,S> {
    _isMounted = true;

    constructor(props: P) {
        super(props)
        let user = getUser()

        this.state = {
            user,
            isSignedIn: !!user,
            isLoading: true,
            hasError: false,

            subscriptions: [],
        }
    }


    componentDidMount = () => {
        //scroll top
        window.scrollTo(0, 0)

        if (!this.state.user) {
            this._getUser()
        }

        this._getSubscriptions();
    }

    componentDidUpdate = () => {
        if(!this.state.isSignedIn) {
            window.location.href = "/";
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {
        const { user, } = this.state

        return (
            <div>
                <Header
                    user={user}
                    onSignedInStateChanged={this._getUser}
                    linkObjects={TOP_HEADER_OBJECTS}
                    isMobile={this.props.isMobile}
                />
                <div className="vh-100">
                    <div className="main-container below-header">
                        <h2 className="text-left my-3">My Subscriptions</h2>
                        <div className="w-100 mb-2 table-responsive">
                            <table className="table invoice-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Action
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Start Date
                                        </th>
                                        <th>
                                            End Date
                                        </th>
                                        <th>
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this._renderSubscriptionBody() }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _getUser = () => {
        let user = getUser()

        if(!this._isMounted) {
            return;
        }

        this.setState({
            user,
            isSignedIn: !!user,
        })
    }

    _getSubscriptions = async () => {
        let url = '/subscription/all';
        try {
            this.setState({
                isLoading: true,
                hasError: false,
            });

            let res = await axios.get<any, AxiosResponse<APIResponse<Subscription[]>>>(url);
            let { success, data } = res.data;

            if(!success) {
                throw new Error();
            }

            this.setState({
                subscriptions: data,
                isLoading: false,
                hasError: false,
            });
        }

        catch (e) {
            this.setState({
                isLoading: false,
                hasError: true,
            });
        }
    }

    _renderSubscriptionBody = () => {
        const { isLoading, hasError, subscriptions } = this.state;
        if(isLoading){
            return (
                <tr>
                    {/* Loader */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <i className="fa fa-2x fa-spin fa-spinner"></i>
                        </div>
                    </td>
                </tr>
            );
        }
    
        if(hasError) {
            return (
                <tr>
                    {/* retry */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <button className="btn btn-sm btn-danger" style={{ height: 20 }} onClick={ this._getSubscriptions }>
                                Retry
                            </button>
                        </div>
                    </td>
                </tr>
            );
        }
    
        if(subscriptions.length === 0){
            return (
                <tr>
                    {/* retry */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <span>You have no subscriptions.</span>
                        </div>
                    </td>
                </tr>
            );
        }
    
        return subscriptions.map( (subscription, index) => <SubscriptionDataRow key={index.toString()} data={subscription}/> );
    }
}

export default SubscriptionPage;
