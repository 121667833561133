import React from 'react';
import './style.scss';

/// <reference path="react-google-login.d.ts"/>
import GoogleSignIn from 'react-google-login/dist/google-login';

//log in api
import { signInWithGoogle } from '../../utils/userAPI';
import { GOOGLE_CLIENT_ID } from '../../Constants/stringConstants';
import { ModalMode } from '../Header';
import { AuthState } from '../../typings';
import GoogleLogo from '../../Icons/google_logo.png';


interface P{
    mode: ModalMode,
    onSigningIn?: () => void,
    onSuccess: ( user: AuthState ) => void,
    onFailure: ( errorMessage: string ) => void,
}

interface S{}


export default class GoogleSignInButton extends React.Component<P,S> {

    constructor(props: P){
        super(props);

        this.state = {

        }

    }

    render() {
        return (
            <GoogleSignIn
                clientId={ GOOGLE_CLIENT_ID }
                buttonText="Login"
                onSuccess={ this._onSuccess }
                onFailure={ this._onFailure }
                cookiePolicy={'single_host_origin'}
                render={ this._renderButton }
            />
        )
    }

    _renderButton = ( renderProps: any ) => {
        const { onSigningIn } = this.props
        return (
            <button 
                className='center border rounded w-100'
                onClick={ () => {
                    if ( onSigningIn ){
                        onSigningIn()
                    }

                    renderProps.onClick()
                }} 
                disabled={renderProps.disabled}
            >
                <img
                    style={{ height: 24, width: 24, marginRight: 24 }}
                    src={GoogleLogo}
                    alt='google-icon'
                />
                <div className="center">
                    <span style={{ fontSize: '0.875rem', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Sign { this.props.mode === 'login'? `in` : 'up' } with Google</span>
                </div>
            </button>

        )
    }

    _onSuccess = async ( res: any ) => {
        let user = await signInWithGoogle( res.tokenObj.id_token )

        if(user !== false){
            this.props.onSuccess( user );
        }

        else {
            this.props.onFailure("Google sign in failed!");
        }
    }   

    _onFailure = ( res: any ) => {
        this.props.onFailure( res )
    }
}