import React, { Component } from 'react';
import { Route, Link, withRouter } from "react-router-dom";

//react-widgets
import 'react-widgets/dist/css/react-widgets.css';

//react-dates
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

//final css
import './App.scss';

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//font-awesome
import 'font-awesome/css/font-awesome.min.css';

//animation
import './keyframes.scss'

/* Pages */
import HomePage from './Pages/HomePage/';
import AboutPage from './Pages/AboutPage/';
import PrivacyPolicy from './Pages/PrivacyPolicyPage';
import TermsAndConditions from './Pages/TermsAndConditionsPage';
import UserSettings from './Pages/UserSettingsPage';
import LoginPage from './Pages/LoginPage';
import MobileFooter from './Components/MobileFooter/MobileFooter';
import { COMPANY_NAME, COMPANY_REG_ID, COMPANY_FACEBOOK, COMPANY_INSTAGRAM, COMPANY_TELEPHONE, COMPANY_ADDRESS, COMPANY_EMAIL, COMPANY_WHATSAPP } from './Constants/stringConstants';
import ContactUsPage from './Pages/ContactUsPage';
import PlansPage from './Pages/PlansPage';
import { getUser } from './utils/utils';
import BillingPage from './Pages/BillingPage';
import SubscriptionPage from './Pages/SubscriptionsPage';
import InvoiceImagePage from './Pages/InvoiceImagePage';
import Campaign from './Pages/Campaign';
import CampaignThankYou from './Pages/CampaignThankYou';

const routes = [
    { path: '/', component: HomePage },
    { path: '/about-us', component: AboutPage },
    { path: '/contact-us', component: ContactUsPage },
    { path: "/plans", component: PlansPage },
    /* { 
        path: "/apartments/:apt_id/", 
        component: withRouter(
            ({ history, location, match, isMobile }) => ( 
                <ApartmentPage 
                    history={ history } 
                    location={ location } 
                    match={ match }
                    isMobile={ isMobile }
                /> 
            ) 
        ), 
    }, */
    { path: "/privacy-policy/", component: PrivacyPolicy },
    { path: "/terms/", component: TermsAndConditions },
    { path: "/settings/", component: UserSettings },
    { path: "/bills/", component: BillingPage },
    { path: "/bills/invoice/:id/image", 
        component: withRouter(
            ({ history, location, match, isMobile }) => ( 
                <InvoiceImagePage 
                    history={ history } 
                    location={ location } 
                    match={ match }
                    isMobile={ isMobile }
                /> 
            ) 
        ),  
    },
    { path: "/subscriptions/", component: SubscriptionPage },
    { path: "/prihatin/", component: Campaign },
    { path: "/prihatin/thank-you", component: CampaignThankYou },
    { path: "/login/", component: LoginPage },
]

export class App extends Component {

    constructor(props) {
        super(props);
        var width = window.innerWidth
        var isMobile = width <= 850;

        this.state = { 
            width: 0, 
            height: 0,
            orientation: "",
            isMobile,
        };
        
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        var orientation = "landscape"
        var width = window.innerWidth
        var isMobile = width <= 850;

        this.setState({ 
            width: window.innerWidth, 
            height: window.innerHeight,
            orientation: orientation,
            isMobile,
        });
    }

    render() {
        const { isMobile, height, width } = this.state;

        return (
            <div className="App">
                <section className="main-section">
                    { routes.map( ({ path, component: Component }, index ) => 
                        <Route 
                            key={ index }
                            exact
                            path={ path }
                            render= {
                                () => (
                                    <Component 
                                        isMobile={ isMobile }
                                        height={ height }
                                        width={ width }
                                    />
                                )
                            }
                        />
                    )}
                </section>

                <footer className='site-footer'>
                    { this._renderFooter() }
                </footer>
            </div>
        )
        
    }

    _renderFooter = () => {
        if ( this.state.isMobile ){
            return (
                <div>
                    <div style={{ marginBottom: '50px' }}>
                        { this._renderPCFooter() }
                    </div>
                    { this._renderMobileFooter() }
                </div>
            )
        }

        return this._renderPCFooter()
    }

    _renderMobileFooter = () => {
        let currentLocation = this.props.location.pathname;
        let aboutUsStyle = null;
        let loginStyle = null;
        let plansStyle = null;
        let homeStyle = null;

        if(currentLocation.includes("about-us")){
            aboutUsStyle = { color: "blue" };
        }

        else if(currentLocation.includes("plans")){
            plansStyle = { color: "blue" };
        }

        else if(currentLocation.includes("login")){
            loginStyle = { color: "blue" };
        }

        else if(currentLocation.includes("settings")){
            loginStyle = { color: "blue" };
        }

        else if(currentLocation === "/"){
            homeStyle = { color: "blue" };
        }

        let user = getUser();
        let isSignedIn = !!user;

        let mobileFooterIcons = [
            { to:"/", iconName:"home", title:"Home", style: homeStyle },
            { to:"/about-us/", iconName:"users", title:"FOSO", style: aboutUsStyle },
            { to:"/plans/", iconName:"handshake", title:"Plans", style: plansStyle },
        ];

        if(!isSignedIn){
            mobileFooterIcons.push({ to:"/login/", iconName:"user", title:"Log In", style: loginStyle });
        }

        else {
            mobileFooterIcons.push({ to:"/settings/", iconName:"user", title:"Settings", style: loginStyle });
        }


        return <MobileFooter icons={ mobileFooterIcons }/>
    }

    _renderPCFooter = () => {
        return  <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <p className="text-justify">{COMPANY_NAME} ({COMPANY_REG_ID})</p>
                            <p className="text-justify">Address: {COMPANY_ADDRESS}</p>
                            <p className="text-justify">TEL: {COMPANY_TELEPHONE}</p>
                            <p className="text-justify">MAIL: {COMPANY_EMAIL}</p>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <p className="text-right">Connect with us</p>
                            <ul className="social-icons">
                                <li><a target="_blank" rel="noopener noreferrer" href={COMPANY_FACEBOOK} title="Facebook"><i className="fa fa-facebook"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href={COMPANY_INSTAGRAM} title="Instagram"><i className="fa fa-instagram"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href={COMPANY_WHATSAPP} title="Whatsapp"><i className="fa fa-whatsapp"></i></a></li>
                            </ul>    
                        </div>

                    </div>
                    <hr/>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12 text-left">
                            <Link
                                className='footer-link'
                                to='/privacy-policy'
                            >
                                Privacy Policy
                            </Link>

                            <Link
                                className='footer-link'
                                to='/terms'
                            >
                                Terms
                            </Link>
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <p className="copyright-text text-right">Copyright &copy; 2024 {COMPANY_NAME} <small>({COMPANY_REG_ID})</small>  All Rights Reserved</p>
                        </div>

                    </div>
                </div>
                </React.Fragment>
    }

}

export default withRouter(props => <App {...props}/>);
