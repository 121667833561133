import React from 'react';
import axios from '../../Services/axios';
import { AxiosResponse } from 'axios';
import { Invoice, DefaultAPIResponse } from '../../typings';

interface P {
    data: Invoice;
}

interface S {
    isDownloading: boolean;
    isUploading: boolean;
}

export default class InvoiceDataRow extends React.Component<P,S> {

    _inputRef: any;
    constructor(props: any){
        super(props);
        this._inputRef = React.createRef();

        this.state = {
            isDownloading: false,
            isUploading: false,
        }

    }

    render() {
        const { data } = this.props;

        let statusClass = "";
        switch(data.status) {
            case "PENDING":
                statusClass = "text-warning";
                break;
            case "PAID":
                statusClass = "text-success";
                break;
            case "EXPIRED":
                statusClass = "text-danger";
                break;
            default:
                break;
        }
        return (
            <tr>
                <td>
                    { this._renderButton() }
                </td>
                <td>
                    {data.name}
                </td>
                <td>
                    {data.date}
                </td>
                <td>
                    {data.due_date}
                </td>
                <td>
                    {data.total}
                </td>
                <td>
                    { this._renderImage() }
                </td>
                <td className={statusClass}>
                    {data.status}
                </td>
            </tr>
        )
    }

    _renderButton = () => {
        if(this.state.isDownloading){
            return (
                <div className="btn btn-sm btn-success download-btn h-100">
                    <i className="fa fa-spin fa-spinner"></i>
                </div>
            )
        }

        return (
            <button className="btn btn-sm btn-success download-btn h-100" onClick={ () => this._onDownload() }>
                <i className="fa fa-download"></i>
            </button>
        )
    }

    _renderImage = () => {
        const { data } = this.props;

        if(this.state.isUploading) {
            return (<button className="btn btn-sm btn-info h-100" onClick={ () => this._inputRef.click() }><i className="fa fa-spin fa-spinner"></i></button>
            )
        }

        if(data.is_paid && !data.invoice_image){
            return null;
        }

        if(!data.invoice_image){
            return (
                <div>
                    <input ref={ ref => this._inputRef = ref } type="file" accept=".png,.jpg" name="image" onChange={ (e) => this._handleChange(e.target.files) } className="d-none"/>
                    <button className="btn btn-sm btn-info h-100" onClick={ () => this._inputRef.click() }><i className="fa fa-upload"></i></button>
                </div>
            )
        }

        return (
            <a href={`/bills/invoice/${data.id}/image`} target="_blank" rel="noopener noreferrer">
                View
            </a>
        );
    }

    _handleChange = async (files: FileList | null) => {
        if(!files ){
            return;
        }

        if(files.length !== 1){
            return;
        }


        const { data } = this.props;
        let url = `/invoice/${data.id}/uploadProof`;

        try {
            this.setState({
                isUploading: true,
            });

            var formData = new FormData();
            formData.append("image", files[0]);

            let res = await axios.post<any, AxiosResponse<DefaultAPIResponse>>(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });

            if(!res.data.success){
                throw new Error();
            }

            this.setState({
                isUploading: false,
            });

            window.location.reload();
        }

        catch {
            this.setState({
                isUploading: false,
            });
            alert('Error uploading! Please try again.');
        }
    }

    _onDownload = async () => {
        if(this.state.isDownloading){
            return;
        }

        const { data } = this.props;
        let url = `/invoice/${data.id}/download`;
        
        try {
            this.setState({
                isDownloading: true,
            });

            let res = await axios.get<any, AxiosResponse<Blob>>(url, { responseType: "blob" });

            //actual download of the blob
            var url2 = window.URL.createObjectURL(res.data);
            var a = document.createElement('a');
            a.href = url2;
            a.download = data.name + ".pdf";
            a.click();
            a.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);
            
            this.setState({
                isDownloading: false,
            });
        }

        catch {
            this.setState({
                isDownloading: false,
            });
            alert('Error downloading! Please try again.');
        }
    }
}