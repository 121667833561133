import React, { Component } from 'react';
import { ModalMode } from '../Header'
import './LoginForm.module.scss';
import EmailLoginForm from '../EmailLoginForm';
import GoogleSignInButton from '../GoogleSignInButton';
import FacebookSignInButton from '../FacebookSignInButton';
import { AuthState } from '../../typings';

interface P{
  onSuccess: ((user: AuthState) => void);
  onFailure: ((res:any) => void);
  onSigningIn: () => void;
}

interface S{
    modalMode: ModalMode;
    loggingIn: boolean;
}

class LoginForm extends Component<P,S> {
  
  constructor(props: P) {
    super(props);

    this.state = {
        modalMode: "login",
        loggingIn: false,
    }
  }
  
  render() {
    return (this._renderLoginForm());
  }

  _renderLoginForm = () => {
    const { modalMode } = this.state;
    return (
        <div className="modal-input d-flex flex-column align-items-start">
            <div className="position-relative w-100">
                <div
                    className='center' 
                    style={{ marginBottom: '1rem' }}
                >
                    <GoogleSignInButton
                        mode={ modalMode }
                        onSigningIn={ this.props.onSigningIn }
                        onSuccess={ this._onSignIn }
                        onFailure={ this._onFailedSignIn }
                    />
                </div>
                <div
                    className='center' 
                    style={{ marginTop: '1rem' }}
                >
                    <FacebookSignInButton
                        mode={ modalMode }
                        onSigningIn={ this.props.onSigningIn }
                        onSuccess={ this._onSignIn }
                        onFailure={ this._onFailedSignIn }
                    />
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
                <div className='hairline-divider'/>
                <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>OR</span>
                <div className='hairline-divider'/>
            </div>

            <EmailLoginForm
                mode={ modalMode }
                onSuccess={ this._onSignIn }
                onFailure={ this._onFailedSignIn }
            />

                
            {
                modalMode === 'login' && <button
                                            className="p-0"
                                            style={{
                                                textTransform: 'none',
                                                outline: 'none',
                                            }}
                                        >
                                            Forgot your password?
                                        </button>
            }

            {
                modalMode === 'login' &&    <button 
                                                className="p-0"
                                                style={{
                                                    textTransform: 'none',
                                                    outline: 'none',
                                                }}
                                                onClick={ () => this.setState({ modalMode: 'signup', loggingIn: true })}
                                            >
                                                <strong>Don't have an account? Sign up now!</strong> 
                                            </button>
            }

            {
                modalMode === 'signup' &&    <button 
                                                className="p-0"
                                                style={{
                                                    textTransform: 'none',
                                                    outline: 'none',
                                                }}
                                                onClick={ () => this.setState({ modalMode: 'login', loggingIn: true })}
                                            >
                                                <strong>Already have an account? Log in instead!</strong> 
                                            </button>
            }
        </div>
      )
  }

  _onSignIn = ( user: AuthState ) => {
      this.props.onSuccess(user)
  }

  _onFailedSignIn = ( res: any ) => {
      this.props.onFailure(res)
  }

}

export default LoginForm;
