import React from 'react';
import './style.scss';
import Header from '../../Components/Header';
import { getUser, clearUser, setUser } from '../../utils/utils';
import { updateUser, changePassword, getMe } from '../../utils/userAPI';
import { Redirect } from 'react-router';
import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';
import { AuthState, UserDetails } from '../../typings';

interface P{
    isMobile: boolean,
    history: any,
}

interface S{
    isLoading: boolean;
    user?: AuthState,
    userDetails?: UserDetails,
    password: string,
    confirmPassword: string,
    oldPassword: string,
}

export default class UserSettingsPage extends React.Component<P,S> {

    constructor(props: P){
        super(props);

        let user = getUser();

        this.state = {
            isLoading: true,
            user,
            userDetails: undefined,
            password: '',
            confirmPassword: '',
            oldPassword: '',
        }

    }

    componentDidMount = async() => {
        //scroll top
        window.scrollTo(0, 0)

        try {
            let me = await getMe();
            
            this.setState({
                userDetails: me,
                isLoading: false,
            });
        }

        catch {
            alert('Unable to get your details! Please try again later!');
        }
    }

    _getUser = () => {
        let user = getUser()

        this.setState({
            user,
        })
    }

    _onSignOut = () => {
        clearUser()
        window.location.href = "/";
    }

    render() {
        const { user, userDetails, isLoading } = this.state;
        const { isMobile } = this.props;

        if ( !user ){
            return  <Redirect
                        to='/'
                    />
        }

        return (
            <div style={{ display: 'flex', height: '100%', width: '100vw' }}>
                <Header
                    user={ user }
                    onSignedInStateChanged={ this._getUser }
                    linkObjects={ TOP_HEADER_OBJECTS }
                    isMobile={ isMobile }
                    headerMode='border'
                />
                <div className='below-header px-5 mx-auto' style={{ flex: 1, maxWidth: '750px' }}>

                    <div className="card">
                        <div className="card-body">
                            <div className='border-bottom text-sm-left py-3 mb-3'>
                                <strong>User Details</strong>
                            </div>
                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Phone Number:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='tel_no'
                                    defaultValue={ userDetails?.tel_no }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.tel_no = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    NRIC:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='nric'
                                    defaultValue={ userDetails?.nric }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.nric = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>


                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Address:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='address'
                                    defaultValue={ userDetails?.address }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.address = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>


                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    State:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='state'
                                    defaultValue={ userDetails?.state }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.state = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Postcode:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='postcode'
                                    defaultValue={ userDetails?.postcode }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.postcode = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            {/* <div style={{ display: 'flex' }}>
                                <div className='user-settings-desc fixed-size'>
                                    <strong>
                                        Email:
                                    </strong>
                                </div>
                                <input
                                    className='user-settings-input hairline-border'
                                    type='text'
                                    placeholder='Email'
                                    name='email'
                                    defaultValue={ email }
                                    onChange={ ( event ) => this.setState({ phoneNum: event.target.value })}
                                />
                            </div> */}

                            <div className='border-bottom text-sm-left py-3 mb-3'>
                                <strong>Company Details</strong>
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Name:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='company_name'
                                    defaultValue={ userDetails?.company_name }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.company_name = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Address:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='company_address'
                                    defaultValue={ userDetails?.company_address }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.company_address = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Registration Number:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='company_registration_number'
                                    defaultValue={ userDetails?.company_registration_number }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.company_registration_number = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Nature:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='company_nature'
                                    defaultValue={ userDetails?.company_nature }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.company_nature = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Website:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='company_website'
                                    defaultValue={ userDetails?.company_website }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.company_website = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                        Phone Number:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='company_phone_number'
                                    defaultValue={ userDetails?.company_phone_number }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.company_phone_number = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                        Email:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='text'
                                    placeholder='---'
                                    name='company_email'
                                    defaultValue={ userDetails?.company_email }
                                    onChange={ ( event ) =>  {
                                        let userDetails = this.state.userDetails;
                                        if(userDetails){
                                            userDetails.company_email = event.target.value;
                                            this.setState({userDetails});
                                        }
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button 
                                    className='user-settings-button'
                                    onClick={ this._updateUser }
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-2">
                        <div className="card-body">

                            <div className='border-bottom text-sm-left py-3 mb-3'>
                                <strong>Change Password</strong>
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                        Old Password:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='password'
                                    placeholder=''
                                    name='old_password'
                                    onChange={ ( event ) => this.setState({ oldPassword: event.target.value })}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Password:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='password'
                                    placeholder=''
                                    name='password'
                                    onChange={ ( event ) => this.setState({ password: event.target.value })}
                                />
                            </div>

                            <div className="row m-0">
                                <label className="col-md-3 col-sm-12 text-left px-0">
                                    Confirm Password:
                                </label>
                                <input
                                    className='col-md-9 col-sm-12 form-control mb-2'
                                    type='password'
                                    placeholder=''
                                    name='confirm_password'
                                    onChange={ ( event ) => this.setState({ confirmPassword: event.target.value })}
                                />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button 
                                    className='user-settings-button'
                                    onClick={ this._changePassword }
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>

                    <button 
                        className='my-3 w-100 mx-0 p-0 btn-info rounded'
                        onClick={ this._onSignOut }
                    >
                        Log Out
                    </button>

                </div>
            </div>
        )
    }

    _updateUser = async () => {
        let params: UserDetails | undefined = this.state.userDetails;

        if(!params) {
            alert('Error occured!');
            return;
        }

        try {
            let res = await updateUser( params );
    
            if ( res?.data.success ){
                let user = getUser();
                user!.telNo = this.state.userDetails!.tel_no!;
                setUser(user!);
                alert( 'Saved!' );
                return
            }

            else {
                throw new Error();
            }
        }

        catch {
            alert('Error occured! Please try again!');
        }
    }

    _changePassword = async() => {
        const { password, confirmPassword, oldPassword } = this.state

        if ( confirmPassword !== password ){
            alert( 'Passwords do not match!' )
            return
        }

        try {
            let res = await changePassword( password, oldPassword )

            if ( res?.data.success ){
                alert( 'Saved!' );
                return
            }
        }

        catch (e) {
            if(e.response?.data?.message){
                alert(e.response.data.message);
            }

            else {
                alert('Error occured! Please try again!');
            }
        }

    }
}