import React, { Component } from 'react'
import './SocialButton.css'
import PropTypes from 'prop-types'
import fbLogo from '../../Icons/facebook-circle-300x300.png'
import wsLogo from '../../Icons/whatsapp-circle-300x300.png'
import abnbLogo from '../../Icons/airbnb-circle-300x300.png'
import emailLogo from '../../Icons/email-circle-300x300.png'
import instaLogo from '../../Icons/instagram-circle-300x300.png'

export class SocialButton extends Component {

    render() {
        return (
            <div className='hover-enlarge'>
               
                <a
                    href={ this.props.link }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    { this._renderIcon() }
                </a>
            </div>
        )
    }

    _renderIcon() {

        var logo;
        switch ( this.props.iconName ){
            case "Facebook":
                logo = fbLogo;
                break;

            case "WhatsApp":
                logo = wsLogo;
                break;

            case "Airbnb":
                logo = abnbLogo;
                break;

            case "Email":
                logo = emailLogo;
                break;

            case "Insta":
                logo = instaLogo;
                break;

            default:
                logo = "";
                break;
        }

        if ( logo !== "" ){

            return (
                <div className="Social-button">
                    <img src={ logo } alt={ `${this.props.iconName}-logo` }/>
                </div>
            )

        }
    }
}

SocialButton.propTypes = {
    iconName: PropTypes.string,
    link: PropTypes.string.isRequired,
}

export default SocialButton
