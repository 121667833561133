import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.scss'
import { RECAPTCHA_API_KEY } from '../../Constants/stringConstants';

interface P {
    onSubmit: (recaptcha: string) => void;
}

interface S {}

const recaptchaRef: React.RefObject<ReCAPTCHA> = React.createRef();
export class ReCAPTCHAForm extends Component<P,S> {

    _onSubmit = async ( event: React.MouseEvent ) => {

        event.preventDefault()
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()

        const recaptchaValue = recaptchaRef.current!.getValue();

        if(recaptchaValue){
            this.props.onSubmit(recaptchaValue);
        }

        else {
            alert("Please tick recaptcha!");
        }
    }

    render() {
        return (
            <form className="text-left">
                {this.props.children}
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_API_KEY}
                />
                <button className="btn btn-sm btn-info px-3 py-0" onClick={this._onSubmit}>Submit</button>
            </form>
        )
    }

    reset = () => {
        recaptchaRef.current!.reset();
    }
}

export default ReCAPTCHAForm;
