import React, { Component } from 'react'
import './SocialBar.scss'
import SocialButton from '../SocialButton/SocialButton';
import { COMPANY_FACEBOOK, COMPANY_EMAIL, COMPANY_WHATSAPP, COMPANY_INSTAGRAM } from '../../Constants/stringConstants';

export class SocialBar extends Component {
    render() {
        return (
            <div className="Social-bar">
                <span className="Social-text">Connect With Us</span>
                <div className="Social-button-container">
                    <SocialButton 
                        iconName="Facebook"
                        link={COMPANY_FACEBOOK}
                    />
                    <SocialButton 
                        iconName="WhatsApp"
                        link={COMPANY_WHATSAPP}
                    />
                    <SocialButton 
                        iconName="Insta"
                        link={COMPANY_INSTAGRAM}
                    />
                    <SocialButton 
                        iconName="Email"
                        link={COMPANY_EMAIL}
                    />
                </div>
            </div>
        )
    }
}

export default SocialBar
