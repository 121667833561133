import React, { Component } from 'react'
import './VerticalIconWithTitle.scss'

import PropTypes from 'prop-types'

//icons
import { 
    FaTools,
    FaUsers, 
    FaAirbnb,
    FaHome,
    FaUser,
    FaHandshake,
} from 'react-icons/fa'

import {
    GiMoneyStack, 
} from 'react-icons/gi'

import {
    IoIosCalendar
} from 'react-icons/io'


export class VerticalIconWithTitle extends Component {
    render() {
        const { title, desc, titleSize } = this.props;
        return (
            <div className="Vertical-icon-with-desc-wrapper">
                <div className="Vertical-icon-wrapper center">
                    { this._renderIcon() }
                </div>
                <div className="Vertical-icon-desc-wrapper">
                    <span className="Vertical-icon-title" style={ { fontSize: titleSize? titleSize : '1rem' } }>{ title }</span>
                    <span className="Vertical-icon-desc">{ desc }</span>
                </div>
            </div>
        )
    }

    _renderIcon() {
        const { iconName, iconSize } = this.props;
        var adjIconSize = iconSize? iconSize : 100;

        switch ( iconName ) {
            case "tools":
                return <FaTools size={ adjIconSize }/>

            case "users":
                return <FaUsers size={ adjIconSize }/>

            case "airbnb":
                return <FaAirbnb size={ adjIconSize }/>

            case "price":
                return <GiMoneyStack size={ adjIconSize }/>

            case "calendar":
                return <IoIosCalendar size={ adjIconSize }/>
            
            case "home":
                return <FaHome size={ adjIconSize }/>
            
            case "handshake":
                return <FaHandshake size={ adjIconSize }/>
            
            case "user":
                return <FaUser size={ adjIconSize }/>
                
            default:
                break;
        }
    }
}

VerticalIconWithTitle.propTypes = {
    iconSize: PropTypes.number,
    title: PropTypes.string.isRequired,
    titleSize: PropTypes.string,
    desc: PropTypes.string,
    iconName: PropTypes.string.isRequired,
}

export default VerticalIconWithTitle
