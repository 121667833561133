//company details
export const COMPANY_NAME = 'Foso Business Center';
export const COMPANY_ADDRESS = 'J-5-3 Pusat Perdagangan Bandar Bukit Jalil, Persiaran Jalil 1, 57000 Bukit Jalil, W. P. Kuala Lumpur.';
export const COMPANY_TELEPHONE = '010-263 3676';
export const COMPANY_REG_ID = '003119837-A';
export const COMPANY_EMAIL = 'admin@fosobiz.com';
export const COMPANY_FACEBOOK = 'https://www.facebook.com/fosobiz';
export const COMPANY_INSTAGRAM = '';
export const COMPANY_WHATSAPP = 'https://wa.me/60102633676?text=Hi I would like to know more about Foso Business Center!';

export const GOOGLE_CLIENT_ID = '70207617840-isjk9is27ehgovno51t7alnoeq6jqp1e.apps.googleusercontent.com';
export const GOOGLE_API_KEY = 'AIzaSyBh6oNBMRudP4Zq8m0NygqKN0nNBJHVPQY';
export const RECAPTCHA_API_KEY = window.location.href.includes('foso.test') || window.location.href.includes('localhost')? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : "6LcWnN8ZAAAAAAPtW4-JZHKvNTlYOXtSmxODrIE8" ;

//todo delete this and use commented APP ID
export const FACEBOOK_APP_ID = '788236171954915';

//api constants
export const USER_EXTENSION = 'customer';