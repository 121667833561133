import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import axios from '../../Services/axios';

import './style.scss';

//custom components
import Header from '../../Components/Header';

import SocialBar from '../../Components/SocialBar/SocialBar';
import { getUser } from '../../utils/utils';

//icons
import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';

export class HomePage extends Component {

    _isMounted = true;

    constructor(props) {
        super(props);

        let user = getUser()

        this.state = { 
            areaNames: [],
            user,
            isSignedIn: !!user,
            bannerUrls: [],
            bannerLoading: true,
            bannerError: false,
        };
    }

    async componentDidMount(){
        //scroll top
        window.scrollTo(0, 0);

        if ( !this.state.user ){
            this._getUser()
            this._getBannerUrls();
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    _getBannerUrls = async() => {
        try {
            let { data } = await axios.get('/banners');

            this.setState({
                bannerUrls: data.data,
                bannerError: false,
                bannerLoading: false,
            })
        }

        catch {
            this.setState({
                bannerError: true,
                bannerLoading: false,
            })
        }
    }

    render() {
        let { user } = this.state

        let backgroundContainerStyle = this.props.isMobile? { marginTop: "2rem" } : null;

        return (
            <div>
                <Header 
                    user={ user }
                    onSignedInStateChanged={ this._getUser }
                    linkObjects={ TOP_HEADER_OBJECTS }
                    isMobile={ this.props.isMobile }
                />
                <div className="Main-content-container">
                    <div className="section">
                        <div className="section-1">
                            {
                                !this.state.bannerError && !this.state.bannerLoading && (
                                    <div id="banners">
                                        {
                                            this.state.bannerLoading && <div class="center banner-loader">{/* <i class="fa fa-2x fa-spin fa-spinner"></i> */}</div>
                                        }
                                        {
                                            !this.state.bannerLoading && <Carousel interval={1000}>
                                                                            {
                                                                                this.state.bannerUrls.map((url, index) => (
                                                                                    <Carousel.Item key={index.toString()}>
                                                                                        <img
                                                                                            className="d-block w-100"
                                                                                            src={url}
                                                                                            alt={`banner_${index}`}
                                                                                        />
                                                                                    </Carousel.Item>
                                                                                ))
                                                                            }
                                                                        </Carousel>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="section" style={backgroundContainerStyle}>
                        <div className="section-2">
                            <div className="main-page-promo-img right">
                                <img className="Home-page-background" src="/assets/images/HomePic1.jpg" alt="main-background"/>
                            </div>
                            <div className="img-caption left">
                                <div className="sec2-desc">
                                    <div className="title-divider"/>
                                    <span className="title-1">
                                        <h3>
                                        Make Your Business Look Good
                                        </h3>
                                    </span>
                                    <p>Own a prestigious address from <strong>RM65</strong> a month or <strong>RM650</strong> a year at <strong>Bukit Jalil Signature Shops @ Bukit Jalil City</strong>, right next to the upcoming Pavillion 2! Work here with our complimentary fresh brewed coffee and classic jazz music. <strong>Sign up and become a member today!</strong> We will get in touch promptly.</p>
                                    {/* <div>
                                        <Link
                                            className="learn-more-button btn no-deco"
                                            to="/about-us/#contact-us"
                                        >
                                            Learn more
                                        </Link>
                                    </div> */}
                                        
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section" style={backgroundContainerStyle}>
                        <div className="section-7">
                            <div className="main-page-promo-img2">
                                <img className="Home-page-background" src="/assets/images/pavillion2.png" alt="main-background"/>
                            </div>
                            <div className="sec7-desc">
                                <div className="title-divider"/>
                                <span className="title-1">
                                    <h3>
                                    2 mins walking distance from Pavillion 2 @ Bukit Jalil
                                    </h3>
                                </span>
                                <p>Enhance your business image alongside with the next mega project. It's just steps away! Ample carparks too!</p>
                                {/* <div>
                                    <Link
                                        className="learn-more-button btn no-deco"
                                        to="/contact-us"
                                    >
                                        Learn more
                                    </Link>
                                </div> */}
                                    
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="section-6">
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/laptop1.png" alt="laptop"/>
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4 className="p-0 m-0">
                                    Addresses<br/>
                                    </h4>
                                    <p>From RM50</p>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/chairs1.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4 className="p-0 m-0">
                                    Virtual Offices<br/>
                                    </h4>
                                    <p>From RM150</p>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/chairs3.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4 className="p-0 m-0">
                                    CoWork Spaces<br/>
                                    </h4>
                                    <p>From RM350</p>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/chairs4.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4 className="p-0 m-0">
                                    Events and Meetings<br/>
                                    </h4>
                                    <p>From RM150</p>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="section cls-homepage">
                        <div className="section-4">
                            <div className="cls-homepage-title">
                                <div className="content-top">
                                    <h3>Amenities<br/>provided</h3>
                                </div>
                            </div>
                        </div>
                        <div className="section-6">
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/foso_night.png" alt="laptop"/>
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    24/7 Access<br/>
                                    </h4>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/high_speed_internet.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    High Speed Internet<br/>
                                    </h4>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/printer.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    A3 A4 Color Printing<br/>
                                    </h4>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/pantry.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    Pantry<br/>
                                    </h4>
                                </span>
                            </div>
                        </div>
                        <div className="section-6">
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/pavillion2_2.png" alt="laptop"/>
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    Pavillion 2<br/>
                                    </h4>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/aurora_place.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    Aurora Place Mall<br/>
                                    </h4>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/parcelhub.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    ParcelHub<br/>
                                    </h4>
                                </span>
                            </div>
                            <div className="sec4-desc">
                                <div className="cls-image-container">
                                    <img src="/assets/images/99speed.png" alt="chairs" />
                                </div>
                                <div className="title-divider"/>
                                <span className="title-2">
                                    <h4>
                                    SpeedMart<br/>
                                    </h4>
                                </span>
                            </div>
                        </div>
                    
                    </div>

                    <div className="section">  
                        <div className="center flex-column contact-us-box">
                            <h4>Get in touch to discuss opportunities with our experts.</h4>
                            <Link to="/contact-us">
                                Contact Us For More Details &gt;&gt;
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }

    _renderSocialBar() {
        if ( !this.props.isMobile ){
            return (
                <SocialBar/>
            )
        } 
    }

    _getUser = () => {
        let user = getUser()

        if(!this._isMounted) {
            return;
        }

        this.setState({
            user,
            isSignedIn: !!user && user.userId,
        })
    }
}

export default HomePage
