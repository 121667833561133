import React, { Component } from 'react'
import './style.scss'

import Header from '../../Components/Header';
import { getUser } from '../../utils/utils';

import { Tabs, Tab } from 'react-bootstrap';
import InvoiceDataRow from '../../Components/InvoiceDataRow';
import ReceiptDataRow from '../../Components/ReceiptDataRow';

//font-awesome
import 'font-awesome/css/font-awesome.min.css';

import { TOP_HEADER_OBJECTS } from '../../Constants/objectConstants';
import axios from '../../Services/axios';
import { AuthState, APIResponse, Invoice, Receipt } from '../../typings';
import { AxiosResponse } from 'axios';

interface P {
    isMobile: boolean;
}

interface S {
    user?: AuthState;
    isSignedIn: boolean;

    isInvoiceLoading: boolean;
    isInvoiceError: boolean;
    isReceiptLoading: boolean;
    isReceiptError: boolean;

    invoices: Invoice[];
    receipts: Receipt[];
}

export class BillingPage extends Component<P,S> {
    _isMounted = true;

    constructor(props: P) {
        super(props)
        let user = getUser()

        this.state = {
            user,
            isSignedIn: !!user,
            isInvoiceLoading: true,
            isInvoiceError: false,
            isReceiptLoading: true,
            isReceiptError: false,
            invoices: [],
            receipts: [],
        }
    }


    componentDidMount = () => {
        //scroll top
        window.scrollTo(0, 0)

        if (!this.state.user) {
            this._getUser()
        }

        this._getInvoices();
        this._getReceipts();
    }

    componentDidUpdate = () => {
        if(!this.state.isSignedIn) {
            window.location.href = "/";
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    render() {
        const { user, } = this.state

        return (
            <div>
                <Header
                    user={user}
                    onSignedInStateChanged={this._getUser}
                    linkObjects={TOP_HEADER_OBJECTS}
                    isMobile={this.props.isMobile}
                />
                <div className="vh-100">
                    <div className="main-container below-header">
                        <h2 className="text-left my-3">My Billing Dashboard</h2>
                        <Tabs defaultActiveKey="invoices" id="uncontrolled-tab-example">
                            <Tab eventKey="invoices" title="Invoices">
                                <div className="px-5 py-3 border-left border-bottom border-right mb-2">
                                    <div className="w-100 text-left mb-2">
                                        <h3>
                                            My Invoices
                                        </h3>
                                    </div>
                                    <div className="w-100 mb-2 table-responsive">
                                        <table className="table invoice-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Action
                                                    </th>
                                                    <th>
                                                        Invoice No.
                                                    </th>
                                                    <th>
                                                        Invoice Date
                                                    </th>
                                                    <th>
                                                        Due Date
                                                    </th>
                                                    <th>
                                                        Total (RM)
                                                    </th>
                                                    <th>
                                                        Proof of Payment
                                                    </th>
                                                    <th>
                                                        Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this._renderInvoiceBody() }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="receipts" title="Receipts">
                                <div className="px-5 py-3 border-left border-bottom border-right mb-2">
                                    <div className="w-100 text-left mb-2">
                                        <h3>
                                            My Receipts
                                        </h3>
                                    </div>
                                    <div className="w-100 mb-2 table-responsive">
                                        <table className="table invoice-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Action
                                                    </th>
                                                    <th>
                                                        Receipt No.
                                                    </th>
                                                    <th>
                                                        Receipt Date
                                                    </th>
                                                    <th>
                                                        Total (RM)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this._renderReceiptBody() }
                                            </tbody>
                                        </table>
                                    </div>
                                
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

    _getUser = () => {
        let user = getUser()

        if(!this._isMounted) {
            return;
        }

        this.setState({
            user,
            isSignedIn: !!user,
        })
    }

    _getInvoices = async () => {
        let url = '/invoice/all';
        try {
            this.setState({
                isInvoiceLoading: true,
                isInvoiceError: false,
            });

            let res = await axios.get<any, AxiosResponse<APIResponse<Invoice[]>>>(url);
            let { success, data } = res.data;

            if(!success) {
                throw new Error();
            }

            this.setState({
                invoices: data,
                isInvoiceLoading: false,
                isInvoiceError: false,
            });
        }

        catch (e) {
            this.setState({
                isInvoiceLoading: false,
                isInvoiceError: true,
            });
        }
    }

    _renderInvoiceBody = () => {
        const { isInvoiceLoading, isInvoiceError, invoices } = this.state;
        if(isInvoiceLoading){
            return (
                <tr>
                    {/* Loader */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <i className="fa fa-2x fa-spin fa-spinner"></i>
                        </div>
                    </td>
                </tr>
            );
        }
    
        if(isInvoiceError) {
            return (
                <tr>
                    {/* retry */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <button className="btn btn-sm btn-danger" style={{ height: 20 }} onClick={ this._getInvoices }>
                                Retry
                            </button>
                        </div>
                    </td>
                </tr>
            );
        }
    
        if(invoices.length === 0){
            return (
                <tr>
                    {/* retry */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <span>You have no invoices.</span>
                        </div>
                    </td>
                </tr>
            );
        }
    
        return invoices.map( (invoice, index) => <InvoiceDataRow key={index.toString()} data={invoice}/> );
    }

    _getReceipts = async () => {
        let url = '/receipt/all';
        try {
            this.setState({
                isReceiptLoading: true,
                isReceiptError: false,
            });

            let res = await axios.get<any, AxiosResponse<APIResponse<Receipt[]>>>(url);
            let { success, data } = res.data;

            if(!success) {
                throw new Error();
            }

            this.setState({
                receipts: data,
                isReceiptLoading: false,
                isReceiptError: false,
            });
        }

        catch (e) {
            this.setState({
                isReceiptLoading: false,
                isReceiptError: true,
            });
        }

    }

    _renderReceiptBody = () => {
        const { isReceiptLoading, isReceiptError, receipts } = this.state;
        if(isReceiptLoading){
            return (
                <tr>
                    {/* Loader */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <i className="fa fa-2x fa-spin fa-spinner"></i>
                        </div>
                    </td>
                </tr>
            );
        }
    
        if(isReceiptError) {
            return (
                <tr>
                    {/* retry */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <button className="btn btn-sm btn-danger" onClick={ this._getReceipts }>
                                Retry
                            </button>
                        </div>
                    </td>
                </tr>
            );
        }
    
        if(receipts.length === 0){
            return (
                <tr>
                    {/* retry */}
                    <td colSpan={6}>
                        <div className="center" style={{ height: "100px" }}>
                            <span>You have no receipts.</span>
                        </div>
                    </td>
                </tr>
            );
        }
    
        return receipts.map( (receipt, index) => <ReceiptDataRow key={index.toString()} data={receipt}/> );
    }
}

export default BillingPage;
