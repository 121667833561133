import React from 'react';
import './style.scss';
/* import { disableBodyScroll, clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock'; */
import { Modal } from 'react-bootstrap';
import LoginForm from '../LoginForm/LoginForm';
import { AuthState } from '../../typings';
import { ModalMode } from '../Header';

interface P{
    show: boolean,
    onModalClose: () => void;
    onSignIn: (user: AuthState) => void;
    onFailedSignIn: (res: any) => void;
}

interface S{
    modalMode: ModalMode;
    loggingIn: boolean;
}

export default class LoginModal extends React.Component<P,S> {

    constructor(props: P){
        super(props);

        this.state = {
            modalMode: "login",
            loggingIn: false,
        }

    }

    componentDidMount = () => {
    }

    /* componentDidUpdate = (prevProps: P) => {
        if(prevProps.show != this.props.show){
            if(this.props.show){
                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                const body = document.body;
                body.style.position = 'fixed';
                body.style.top = `-${scrollY}`;
                console.log(scrollY);
            }
        }
    } */

    render() {
        return (
            this._renderModal()
        )
    }   

    _renderModal = () => {
        const { show, onModalClose } = this.props;

        if ( !show ){
            return null;
        }

        return (

            <Modal
              show={show}
              backdrop="static"
              keyboard={false}
              onHide={onModalClose} 
              animation={false}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{
              }}
            >
                <Modal.Body 
                    style={{
                        height: "90vh",
                        background: 'var(--warning)',
                        paddingLeft: '3rem',
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 0,
                    }}
                >
                    <button 
                        className="center p-0"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '3rem',
                            height: '3rem',
                            outline: 'none',
                        }}
                        onClick={ this.props.onModalClose }
                    >
                        <i className="fa fa-times fa-2x text-danger"></i>
                    </button>
                    <div className="h-100 w-100 center" style={{ backgroundColor: 'white' }}>
                        <LoginForm
                            onSuccess={ user => this.props.onSignIn(user) }
                            onFailure={ this.props.onFailedSignIn }
                            onSigningIn={ () => this.setState({ loggingIn: true })}
                        >
                        </LoginForm>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}