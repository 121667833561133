import React from 'react';
import axios from '../../Services/axios';
import { AxiosResponse } from 'axios';
import { Receipt } from '../../typings';

interface P {
    data: Receipt;
}

interface S {
    isDownloading: boolean;
}

export default class ReceiptDataRow extends React.Component<P,S> {

    constructor(props: any){
        super(props);

        this.state = {
            isDownloading: false,
        }

    }

    render() {
        const { data } = this.props;
        return (
            <tr>
                <td>
                    { this._renderButton() }
                </td>
                <td>
                    {data.name}
                </td>
                <td>
                    {data.date}
                </td>
                <td>
                    {data.total}
                </td>
            </tr>
        )
    }

    _renderButton = () => {
        if(this.state.isDownloading){
            return (
                <div className="btn btn-sm btn-success download-btn h-100">
                    <i className="fa fa-spin fa-spinner"></i>
                </div>
            )
        }

        return (
            <button className="btn btn-sm btn-success download-btn h-100" onClick={ () => this._onDownload() }>
                <i className="fa fa-download"></i>
            </button>
        )
    }

    _onDownload = async () => {
        if(this.state.isDownloading){
            return;
        }

        const { data } = this.props;
        let url = `/receipt/${data.id}/download`;
        
        try {
            this.setState({
                isDownloading: true,
            });

            let res = await axios.get<any, AxiosResponse<Blob>>(url, { responseType: "blob" });

            //actual download of the blob
            var url2 = window.URL.createObjectURL(res.data);
            var a = document.createElement('a');
            a.href = url2;
            a.download = data.name + ".pdf";
            a.click();
            a.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);
            
            this.setState({
                isDownloading: false,
            });
        }

        catch {
            this.setState({
                isDownloading: false,
            });
            alert('Error downloading! Please try again.');
        }
    }
}