import axios from "../Services/axios";
import { getUserFromId } from "./userAPI";
import { AuthState } from "../typings";

/**
 * Returns cloned object to avoid mutation.
 * @param obj
 */
export const cloneObj = ( obj: any ) => {
    return JSON.parse( JSON.stringify( obj ) )
}

/**
 * Returns pixel coordinates of lat and lng
 * 
 * @param map 
 * @param maps 
 * @param lat 
 * @param lng 
 */
export const fromLatLngToPoint = ( map: any, maps: any, lat: string, lng: string ) => {
    var latLatObj = new maps.LatLng( lat, lng )
    var scale = Math.pow(2, map.getZoom());
    var proj = map.getProjection();
    var bounds = map.getBounds();

    var nw = proj.fromLatLngToPoint(
        new maps.LatLng(
        bounds.getNorthEast().lat(),
        bounds.getSouthWest().lng()
        ));
    var point = proj.fromLatLngToPoint( latLatObj );

    return new maps.Point(
        Math.floor((point.x - nw.x) * scale),
        Math.floor((point.y - nw.y) * scale)
    );
}

/**
 * Returns unified timestamp
 * @param date 
 */
export const getUnifiedTime = ( date?: Date ) => {
    
    if ( date ) {
        //current time offset by timezone offset in seconds
        return ( date.setHours( 0, 0, 0, 0 ) / 1000 ) - ( date.getTimezoneOffset() * 60 )
    }
    return null
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         USER UTILS         ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Gets user token obtained previously
 */
export const getUser = (): AuthState | undefined => {
    let userStr = localStorage.getItem( 'user' )

    if ( !userStr || userStr === 'undefined' ){
        return undefined
    }

    let user = JSON.parse( userStr )
    let { token } = user

    if ( !token ){
        return undefined
    }
    
    return user
}

export const isUserValid = async() => {
    let userStr = localStorage.getItem( 'user' )

    if ( !userStr ){
        return false
    }

    let user: AuthState = JSON.parse( userStr )
    let { userId } = user

    if ( !userId ){
        return false
    }

    let { data } = await getUserFromId( userId )

    if(data.success){
        clearUser();
        setUser(data.data);
    }

    return data.success;
}

/**
 * Sets user token obtained from wp.nesthomemy.com
 * @param token 
 */
export const setUser = ( user: AuthState ) => {
    return localStorage.setItem( 'user', JSON.stringify( user ) )
}

/**
 * Clears previously stored token. To be used in log out.
 */
export const clearUser = () => {
    return localStorage.removeItem('user');
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         AXIOS UTILS        ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Custom axios post method with data for wordpress. Returns wordpress res.
 * @param data 
 */
export const post = async( url: string, data?: { [ key: string ]: string } ) => {
    let form = new FormData()

    for( const key in data ){
        form.append( key, data[ key ] )
    }

    return ( await axios.post( url, form ) ).data
}

export const runIfFunction = (fn: any, ...args: any) => {
    if(typeof(fn) == 'function'){
        fn(...args);
    }
}
